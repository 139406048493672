import http from './httpService';

const { REACT_APP_WEB_API_URL: web_api_url} = process.env;

export async function forgotPassword(email) {
    return http.post(`${web_api_url}/forgot-password`, { email });
}

export function changePassowrd(password, token) {
    return http.post(`${web_api_url}/change-password`, { password, token });
}

 export default {
    forgotPassword,
    changePassowrd
 }
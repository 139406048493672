import React, { Component } from 'react';
import {LineChart, CartesianGrid,YAxis, XAxis, Tooltip, Line, ResponsiveContainer} from 'recharts'
import { getLanguage } from '../../global/language'

const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ];
class ChartTwo extends Component {

    render() { 
        return ( 
            <div className="second-chart">
                <span className="chart-title text-change">VISIOcoach {getLanguage().saliency}</span>
                <ResponsiveContainer>
                    <LineChart data={data} >
                        <CartesianGrid />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="pv" stroke="#f8a900" strokeWidth= "3" />
                        <Line type="monotone" dataKey="uv" stroke="#1875f0" strokeWidth= "3" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
         );
    }
}
 
export default ChartTwo;
import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import authService from '../../services/authService'

const ProtectedRoute = ({ path, roles, component: Component, render, ...rest }) => {

    const user = authService.getCurrentUser();

    var userRoles = [];
    if (roles) userRoles = roles.split(',');

    return (
        <Route {...rest} render={props => {
            if (!user) {
                return (
                    <Redirect to='/login' />
                )
            }
            else if((roles && !userRoles.includes(user.subscription)))
            {
                return (
                    <Redirect to='/' />
                )
            }
            return Component ? <Component {...props} {...rest} /> : render(props);
        }}/>
    );
}

export default ProtectedRoute;

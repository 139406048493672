import http from './httpService';
import axios from 'axios';
var instance = axios.create();
instance.defaults.headers.common = null;
const {
    REACT_APP_WEB_API_URL_CLIENT: client_web_api_url
} = process.env;

export async function postPredifinedQuestions(user_id, version, difficulty, width, height) {
    let screen_size = {
        height: height,
        width: width
    }
    return http.post(`${client_web_api_url}search_screen/predefined`,{user_id,version,screen_size,difficulty});
}

export async function postQuestions(user_id, screen_idx, avg_search_time, eval_method, clicks) {
    return http.post(`${client_web_api_url}search_screen/score`,{ user_id, screen_idx, avg_search_time, eval_method, clicks });
}


export async function getQuestions1( min_search_symbols, max_search_symbols, total_symbols, symbol_size, symbol_set, symbol_primary_color, symbol_bg_color, bg_image, width, height) {
    return http.get(`${client_web_api_url}search_screen/custom?screen_size=${width}:${height}&min_search_symbols=${min_search_symbols}&max_search_symbols=${max_search_symbols}&total_symbols=${total_symbols}&number_of_screens=20&symbol_size=${symbol_size}&symbol_set=${symbol_set}&symbol_primary_color=${symbol_primary_color}&symbol_bg_color=${symbol_bg_color}&bg_image=${bg_image}`);
}

export function postRP(user_id, search_mode, difficulty, symbol_set, symbol_size, symbol_primary_color, symbol_bg_color, height, width) {
    let screen_size = {
        height: height,
        width: width
    }
    return http.post(`${client_web_api_url}search_screen/custom`, {user_id, search_mode, difficulty, symbol_set, symbol_size, symbol_primary_color, symbol_bg_color, screen_size});
}

export function postQuestions1(user_id, min_search_symbols, max_search_symbols, total_symbols, num_different_search_symbols, number_of_screens, symbol_size, symbol_set, symbol_primary_color, symbol_bg_color, bg_image, use_replacement_symbols, replacement_string, replacement_primary_color, replacement_bg_color, height, width) {
    let screen_size = {
        height: height,
        width: width
    }
    return http.post(`${client_web_api_url}search_screen/custom`, {user_id, screen_size, min_search_symbols, max_search_symbols, total_symbols, num_different_search_symbols, number_of_screens, symbol_size, symbol_set, symbol_primary_color, symbol_bg_color, bg_image, use_replacement_symbols, replacement_string, replacement_primary_color, replacement_bg_color});
}

export default {
    postPredifinedQuestions,
    postQuestions,
    getQuestions1,
    postQuestions1,
    postRP
}
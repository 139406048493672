import React from 'react';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import authService from '../../services/authService';
import * as toast from '../../all/toast'
import { Link, withRouter }  from 'react-router-dom'
import RightPart from './right-part';
import { getLanguage } from '../../global/language'

class Login extends Form
{
    state = {
        data: {
            email: '',
            password: ''
        },
        errors: {},
        loading: false
    }
    
    schema = {
        email: Joi.string().email().required(),
        password: Joi.string().required()
    }
    
    componentWillReceiveProps() {
        const { data } = this.state;
        data.password = '';
        this.setState({
            data
        })
    }
    
    submitForm = () => {
        this.setState({
            loading: true
        });
        
        const { email, password } = this.state.data;
        authService.login(email, password).then((data) => {
            console.log(data)
            if (data.error) {
                toast.error(data.error);
                this.setState({
                    loading: false
                });
            }
            else window.location = '/'
        })
    }
    
    render()
    {
        // if (authService.getCurrentUser()) this.props.history.push('/')

        const { data, errors, loading } = this.state;
        const { email, password } = data;
        
        return ( 
            <React.Fragment>
                <section className={`parent`}>
                    <div className={`login login-wrapper`}>   
                        <div className={`left-part`}>
                            <div className={`logo`}>
                                <img src="../img/logo.svg" alt="alt"/>                                
                            </div>
                            <form onSubmit={this.handleSubmit} className="login-form">
                                <span className="title-form text-change">{getLanguage().login}</span>
                                <div className="register-input">
                                    {this.renderInput("email", null, 'email', email, this.handleChange, errors.email, false, `${getLanguage().email_placeholder}`)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("password", null, "password", password, this.handleChange, errors.password, false, `${getLanguage().password_placeholder}`, false, "password")}
                                </div>
                                <div className="loginButton">
                                    {this.renderSubmitButton(`${getLanguage().login1}`, loading)}
                                </div>
                                <Link className="aButtonOne text-change" to="/forgot-password">{getLanguage().forgot_password}</Link>
                                <div className="rectangle"></div>
                                <div className="go-to-register"> 
                                    <span className="register-account text-change">{getLanguage().register_account}</span>
                                    <Link className="aButton text-change" to="/register">{getLanguage().register}</Link>
                                </div>
                            </form>
                            {/* <div className="go-to-homePage">
                                <Link to="">&#8592; Back to HomePage</Link> 
                            </div> */}
                        </div>
                        <div className={`right-part`}>
                            <RightPart />
                        </div>
                    </div>
                </section>
            </React.Fragment>
         ); 
    }
}
 
export default withRouter(Login);
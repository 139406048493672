import React from 'react';
import { withRouter } from 'react-router';
import Form from '../../../../all/common/form';
import sessingSessionService from '../../../../services/settingSessionService';
import * as toast from '../../../../all/toast'
import Joi from 'joi-browser';
import Sailency from '../../versionComponents/sailencyVersion/sailency';
import Standard from './standard';
import { getLanguage } from '../../../../global/language'

class Hermianopia extends Form {
    state = {
        data: {
            difficulty: 'easy',
            version: 'standard',
        },
        firefox: false,
        errors: {}
    }

    schema = {
        difficulty: Joi.string().required(),
        version: Joi.string().required(),
    }


    onFirefox = () => {
        // Firefox 1.0+
        const {data} = this.state;
        var isFirefox = typeof InstallTrigger !== 'undefined';
        
        if(!!isFirefox)
        {
            document.documentElement.mozRequestFullScreen();
        }

        // Safari 30+ && Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        if(!!isChrome || navigator.userAgent.indexOf("Safari") !== -1)
        {
            document.documentElement.webkitRequestFullscreen();
        }
        this.props.history.push({
            pathname: '/session1',
            state: { 
                difficulty: data.difficulty,
                version: data.version
            }
        })  
        this.setState({
            firefox: !this.state.firefox
        })
    }
    
    submitForm = () => {
        const { data }=this.state;
        this.setState({
            loading: true
        })
        sessingSessionService.settingSession( data.difficulty, data.version ).then(({ data: response }) => {
            this.setState({
                loading: false
            })
        this.props.history.push('/session1')         
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    componentWillReceiveProps(nextProps){
        const { data } = this.state;
        if(nextProps.settings.trainingVersion)
        {
            data.version = nextProps.settings.trainingVersion
            console.log(nextProps.settings)
            this.setState({
                data
            })
        }
    }

    render() { 
        const { data, errors } = this.state;
        const { currentTab, professionalVersion } = this.props;
        const { version } = data;
        console.log(version)
        return ( 
            <React.Fragment>
                <div className="settings">
                    <div className="settings-session">
                        <span className="first-title text-change">{getLanguage().select_training}</span>
                        <form>
                            <div className="checkbox">
                                <label className="checkbox-inside text-change">Standard Training
                                    {this.renderRadioButton(version === 'standard', "version", 'standard', this.handleChange, 'standard', errors.version, false, "version")}
                                    <span className="checkmark"></span>
                                </label>
                                <label className="checkbox-inside text-change">Saliency Training
                                    {this.renderRadioButton(version === 'saliency', "version", 'saliency', this.handleChange, 'saliency', errors.version, false, "version")}
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </form>
                        <div className={`standard ${version === 'standard' ? 'active' : 'disable'}`}>
                            <Standard
                                currentTab={currentTab}
                                professionalVersion={professionalVersion}
                                trainingVersion={version}
                                settings={this.props.settings}
                            />
                        </div>
                        <div className={`sailency ${version === 'saliency' ? 'active' : 'disable'}`}>
                            <Sailency
                                currentTab={currentTab}
                                professionalVersion={professionalVersion}
                                trainingVersion={version}
                                settings={this.props.settings}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(Hermianopia);
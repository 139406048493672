import React from 'react';
import Joi from 'joi-browser';
import { withRouter } from 'react-router';
import * as toast from '../../all/toast'
import userService from '../../services/userService';
import Form from '../../all/common/form';
import auth from "../../services/authService";
import ChangePassword from './changePassword';
import { getLanguage } from '../../global/language'

class ProfileElement extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                id:'',
                firstName: '',
                lastName: '',
                email: '',
                street: '',
                zipCode: '',
                city: '',
                houseNumber: '',
                subUser: '',
                subState: ''
            },
            errors: {},
            loading: false,
            active: false,
            deletePopup: false,
            editUser: true
        }
    }

    schema = {
        id: Joi.string().required(),
        firstName: Joi.string().required(),
        lastName: Joi.string().required(),
        email: Joi.string().email().required(),
        // repeatEmail: Joi.string().email().required(),
        street: Joi.string().required(),
        houseNumber: Joi.number().integer().required(),
        zipCode: Joi.number().integer().required(),
        city: Joi.string().required(),
        subUser: Joi.string().allow("").optional(),
        subState: Joi.string().allow("").optional()
    }
    
    componentDidMount () {
        const { data } = this.state;
        userService.getCurrentUser().then(({ data: response }) => {
            data.id = response._id['$oid'];
            data.firstName = response.first_name;
            data.lastName = response.last_name;
            data.email = response.email;
            data.street = response.street;
            data.zipCode = response.zip_code;
            data.city = response.city;
            data.houseNumber = response.house_number;
        
            this.setState({
                data
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    onDelete = () => {
        userService.deleteCurrentUser().then(({ data }) => {
            if(data.success === true)
            {
                auth.logout();

                // window.location = "/";
            }
            toast.success(`${getLanguage().success_delete_user}`)
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    submitForm = () => {
        const { data }=this.state;
        this.setState({
            loading: true
        })
        userService.editCurrentUser( data ).then(({ data: response }) => {
            this.setState({
                loading: false,
                editUser: true
            })
            toast.success(`${getLanguage().success_edit_user}`)
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    onActive = () => {
        this.setState({
            active: !this.state.active
        })
    }

    onDeletePopup = () => {
        this.setState({
            deletePopup: !this.state.deletePopup
        })
    }

    onEditUser = () => {
        this.setState({
            editUser: !this.state.editUser
        })
    }

    render() {
        
        const { data, errors, loading, active, deletePopup, editUser } = this.state;
        const { id, firstName, lastName, street, houseNumber, zipCode, city, subUser, subState } = data;

        return ( 
            <React.Fragment>
                <div className="profile">
                    <ChangePassword onActive={this.onActive} active={active} />
                    <span className="title text-change">{getLanguage().profile}</span>
                    <div className={`parent-popup ${deletePopup ? 'active' : ''}`}>
                        <div className={`delete-popup`}>
                            <span>Are you sure to delete the account? You will lose all user data and subscription!</span>
                            <div className={`popup-button`}>
                                <div className="loginButton">
                                    <button type="button" className="myButton" onClick={this.onDelete} >Yes</button>
                                </div>
                                <div className="loginButton">
                                    <button type="button" className="myButton" onClick={this.onDeletePopup} >No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form key={id} onSubmit={this.handleSubmit}>
                        <div className="left-form">
                            <span className="personalTitle text-change">{getLanguage().personal_info}</span> 
                            <div className="register-input">
                                {this.renderInput("firstName", null, 'firstName', firstName, this.handleChange, errors.firstName, false, `${getLanguage().first_name}`, editUser)}
                            </div>
                            <div className={`register-input`}>
                                {this.renderInput("lastName", null, 'lastName', lastName, this.handleChange, errors.lastName, false, `${getLanguage().last_name}`, editUser)}
                            </div>
                            {/* <span>{getInitials()}</span> */}
                            <div className="register-input">
                                {this.renderInput("street", null, 'street', street, this.handleChange, errors.street, false, `${getLanguage().street}`, editUser)}
                            </div>
                            <div className={`register-input`}>
                                {this.renderInput("houseNumber", null, 'houseNumber', houseNumber, this.handleChange, errors.houseNumber, false, `${getLanguage().home_nr}`, editUser)}
                            </div>
                            <div className="register-input">
                                {this.renderInput("zipCode", null, 'zipCode', zipCode, this.handleChange, errors.zipCode, false,`${getLanguage().zip_code}`, editUser)}
                            </div>
                            <div className={`register-input`}>
                                {this.renderInput("city", null, 'city', city, this.handleChange, errors.city, false, `${getLanguage().city}`, editUser)}
                            </div>
                        </div>
                        <div className={`middle-form`}>

                        </div>
                        <div className="right-form">
                            <span className="subTitle text-change">{getLanguage().subscribed_user}</span>   
                            <div className="register-input">
                                {this.renderInput("subUser", null, 'subUser', subUser, this.handleChange, errors.subUser, false, `${getLanguage().sub_user}`, true)}
                            </div>
                            <div className="register-input">
                                {this.renderInput("subState", null, 'subState', subState, this.handleChange, errors.subState, false, `${getLanguage().sub_state}`, true)}
                            </div>
                        </div>
                        <div className="profile-button">
                            {!editUser ?
                                <div className="loginButton">
                                    {this.renderSubmitButton("Save Action", loading)}
                                </div>
                                :
                                <div className="loginButton">
                                    <span className="myButton text-change" onClick={this.onEditUser} >{getLanguage().edit_profile}</span>
                                </div>
                            }
                            
                            <div className="loginButton">
                                <button type="button" className="myButton text-change" onClick={this.onActive} >{getLanguage().change_pass}</button>
                            </div>
                            <div className="loginButton">
                                <button type="button" className="myButton text-change" onClick={this.onDeletePopup} >{getLanguage().delete_acc}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(ProfileElement);
import React from 'react';
import { withRouter } from 'react-router';

const UpgradeElement = () => {
    return(
        <React.Fragment>
            <div className="upgrade">
                <span className="firstSpan">Upgrade to Full Version</span>
                <p>Please transfer <b>99 EUR</b> to the following account:</p>
                <span className="secondSpan">DE XX XXXX XXXX XXXX XX <br/> BIC 000</span>
                <span className="thirdSpan">The activation will follow within the next 2 bussiness <br/> days of the completed transaction.</span>
            </div>
        </React.Fragment>
    );
}
 
export default withRouter(UpgradeElement);
import React, { Component } from 'react';
import { getLanguage } from '../../global/language'
class FullScreen extends Component {
    state = { 
        active: false
     }

    render() { 
        const { active, onFirefox } = this.props;
        return ( 
            <React.Fragment>
                <div className={`info-parent ${active ? 'active' : ''}`}>
                    <div className="image">
                        <img src="../img/done.svg" alt=""/>
                    </div>
                    <div className="content">
                        <span>{getLanguage().search_symbols}</span>
                        {/* <button onClick={onActive}>fullscreen</button> */}
                        <button onClick={onFirefox} >{getLanguage().full_screen}</button>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default FullScreen;
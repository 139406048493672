import React, { Component } from 'react';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import RenderComponent from '../sections/render-component';
import DashBoardSection from '../sections/dashboardsection';
import Dashboard from './dashboard';

class Home extends Component {
    state = {};
    render() { 
        return ( 
            <RenderComponent>
                <LeftMenuSection>
                    <LeftMenu/>
                </LeftMenuSection>
                <DashBoardSection>
                    <Dashboard />
                </DashBoardSection>
            </RenderComponent>
         );
    }
}

export default Home;
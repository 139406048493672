import React, { Component } from 'react';
import InfoElement from './info';
import Success from './success';
import Fail from './fail';
import clientService from '../../services/clientService';
import FullScreen from './fullscreen';
import Quit from './quit';
import authService from '../../services/authService';

const {
    REACT_APP_WEB_API_URL_CLIENT: client_web_api_url
} = process.env;

class Question extends Component {
    constructor(props){
        super(props);
        this.state = {
            user_id:authService.getCurrentUser().id,
            screen_idx:0,
            avg_search_time:0,
            start_time:0,
            eval_method:'linear',
            clicks: [],
            search_symbol_count:0,
            search_symbols_length:0,
            width: window.screen.width,
            height: window.screen.height,
            success: false,
            fail: false,
            active: true,
            isFull: true,
            quit: false,
            firefox: false,
            data: {
                id: '',
                image_url: '',
                search_symbols: [],
                distractor_symbols: []
            },
        };
    }

    onActive = () => {
        let { start_time } = this.state
        if(start_time===0){
            start_time=Date.now()
        }
        this.setState({
            // avg_search_time: start_time,
            active: !this.state.active,
            start_time
        })
    }

    onSucces = () => {
        const elements = document.getElementsByClassName("search-symbols");
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            element.style.pointerEvents='all';
        }
        let { data,  user_id, screen_idx, avg_search_time, eval_method, clicks, start_time, search_symbols_length } = this.state;
        avg_search_time = avg_search_time/search_symbols_length;
        clientService.postQuestions( user_id, screen_idx, avg_search_time, eval_method, clicks, start_time ).then(( { data: response }) => {
            if(!response.is_finished){
                response=response.next_screen
                data.id = response.id;
                data.image_url = response.image_url;
                data.search_symbols = [].concat(response.search_symbols);
                data.distractor_symbols = response.distractor_symbols;
                data.replacement_symbol_url = response.replacement_symbol_url;
                let coords_length=0
                for (let index = 0; index < response.search_symbols.length; index++) {
                    const element = response.search_symbols[index];
                    coords_length +=element.coords.length
                }
                this.setState({
                    data,
                    start_time:0,
                    search_symbol_count:0,
                    screen_idx: screen_idx+1,
                    search_symbols_length:coords_length,
                    success: !this.state.success,
                    active: !this.state.active,
                })
            }
        })
    }

    onFail = () => {
        this.setState({
            fail: !this.state.fail
        })
    }
    
    onQuit = () => {
        this.setState({
            quit: !this.state.quit
        })
    }
    
    onQuitGame = () => {
        if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
        if(this.props.location.state.currentTab === 'professional'){
            this.props.history.push({
                pathname: '/professional',
                state: {
                    currentTab: this.props.location.state.currentTab
                }
            })
        }else{
            this.props.history.push({
                pathname: '/predefined',
                state: {
                    currentTab: this.props.location.state.currentTab
                }
            }) 
        }
    }


    exitHandler = () => {
        this.setState({
            isFull: !this.state.isFull
        })
    }

    componentDidMount() {
        const {data, width, height ,user_id} = this.state;
        const { difficulty, version, search_mode, search_set, symbol_size, font_color, background_color,min_search_symbols, max_search_symbols, total_symbols, num_different_search_symbols, number_of_screens, symbol_set, symbol_primary_color, symbol_bg_color, bg_image, use_replacement_symbol, replacement_string, replacement_primary_color, replacement_bg_color } = this.props.location.state;
        if(version === "standard" || version === "saliency"){
            clientService.postPredifinedQuestions(user_id, version, difficulty, width, height).then(( { data: response }) => {
                data.id = response.id;
                data.image_url = response.image_url;
                data.search_symbols = [].concat(response.search_symbols);
                data.distractor_symbols = response.distractor_symbols;
                data.replacement_symbol_url = response.replacement_symbol_url;
                let coords_length=0
                for (let index = 0; index < response.search_symbols.length; index++) {
                    const element = response.search_symbols[index];
                    coords_length +=element.coords.length
                }
                this.setState({
                    data,
                    search_symbols_length:coords_length
                })
            })
        }else if(version === "rp"){
            clientService.postRP( user_id, search_mode, difficulty, search_set, symbol_size, font_color, background_color, height, width).then(( { data: response }) => {
                data.id = response.id;
                data.image_url = response.image_url;
                data.search_symbols = [].concat(response.search_symbols);
                data.distractor_symbols = response.distractor_symbols;
                let coords_length=0
                for (let index = 0; index < response.search_symbols.length; index++) {
                    const element = response.search_symbols[index];
                    coords_length +=element.coords.length
                }
                this.setState({
                    data,
                    search_symbols_length:coords_length
                })
            })
        }else if(version === "customized"){
            clientService.postQuestions1( user_id, min_search_symbols, max_search_symbols, total_symbols, num_different_search_symbols, number_of_screens, symbol_size, symbol_set, symbol_primary_color, symbol_bg_color, bg_image, use_replacement_symbol, replacement_string, replacement_primary_color, replacement_bg_color, height, width ).then(( { data: response }) => {
                data.id = response.id;
                data.image_url = response.image_url;
                data.search_symbols = [].concat(response.search_symbols);
                data.distractor_symbols = response.distractor_symbols;
                let coords_length=0
                for (let index = 0; index < response.search_symbols.length; index++) {
                    const element = response.search_symbols[index];
                    coords_length +=element.coords.length
                }
                this.setState({
                    data,
                    search_symbols_length:coords_length
                })
            })
        }
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
        document.addEventListener('mozfullscreenchange', this.exitHandler, false);
        document.addEventListener('fullscreenchange', this.exitHandler, false);
        document.addEventListener('MSFullscreenChange', this.exitHandler, false);
    }

    onFirefox = () => {
        var isFirefox = typeof InstallTrigger !== 'undefined';
        if(!!isFirefox)
        {
            document.documentElement.mozRequestFullScreen();
        }
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        if(!!isChrome || navigator.userAgent.indexOf("Safari") !== -1)
        {
            document.documentElement.webkitRequestFullscreen();
        }
        const { difficulty, version, min_search_symbols, max_search_symbols, total_symbols, number_of_screens, symbol_size, symbol_set, symbol_primary_color, symbol_bg_color, bg_image, use_replacement_symbol, replacement_string, replacement_primary_color, replacement_bg_color, cursor_color, mouse_cursor } = this.props.location.state; 
        this.props.history.push({
            pathname: '/session1',
            state: {
                difficulty: difficulty,
                version: version,
                min_search_symbols: parseInt(min_search_symbols),
                max_search_symbols: parseInt(max_search_symbols),
                total_symbols: parseInt(total_symbols),
                number_of_screens: parseInt(number_of_screens),
                symbol_size: parseInt(symbol_size),
                symbol_set: symbol_set,
                symbol_primary_color: symbol_primary_color,
                symbol_bg_color: symbol_bg_color,
                bg_image: bg_image,
                use_replacement_symbol: use_replacement_symbol,
                replacement_string: replacement_string,
                replacement_primary_color: replacement_primary_color,
                replacement_bg_color: replacement_bg_color,
                cursor_color: cursor_color,
                mouse_cursor: mouse_cursor,
                currentTab: this.props.location.state.currentTab
            }
        }) 
        this.setState({
            firefox : !this.state.firefox
        });
    }

    onSearchSymbolClick = (replacement_url, element_index, coord_index, e)=>{
        let date_now=Date.now();
        console.log(element_index)
        console.log(coord_index)
        let { avg_search_time, success, search_symbol_count, search_symbols_length, start_time, clicks, data} = this.state;
        let { search_symbols } = data
        let element = e.target;
        if(replacement_url){
            element.src=client_web_api_url+replacement_url;
            element.style.pointerEvents='none';
        }else{
            element.style.display='none';
            element.style.pointerEvents='none';
        }
        clicks.push({
            x:search_symbols[element_index].coords[coord_index].x,
            y:search_symbols[element_index].coords[coord_index].y,
            timestamp: date_now,
        })
        if(search_symbol_count+1 === search_symbols_length){
            success = true
            avg_search_time = date_now-start_time
        }
        this.setState({
            search_symbol_count:search_symbol_count+1,
            avg_search_time,
            success,
            clicks
        })
    }

    // onContextMenu = (e) =>{
    //     e.preventDefault();
    // }

    render() { 
        console.log(this.props.location.state)
        let {cursor_color, mouse_cursor,symbol_size,target} = this.props.location.state;
        const { active, success, fail, quit, width, height ,isFull, data, pointerEvents} = this.state;
        const { image_url, search_symbols, distractor_symbols }=data;
        let size = '20px';
        let styles = {};
        if(mouse_cursor && mouse_cursor === 'big')
        {
            size = '55px'
        }
        if(cursor_color){
            const { r,g,b,a } = cursor_color
            styles = {
                cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 320.943 320.943" height="${size}" viewBox="0 0 320.943 320.943" width="${size}" fill="rgba(${ r }, ${ g }, ${ b }, ${ a })" ><path d="m50.147 320.943c-1.391 0-2.791-.284-4.104-.882-3.604-1.616-5.926-5.201-5.926-9.148v-300.881c0-4.055 2.439-7.718 6.19-9.265 3.751-1.557 8.071-.695 10.93 2.174l220.647 220.647c2.87 2.87 3.732 7.179 2.174 10.93-1.548 3.751-5.211 6.19-9.265 6.19h-126.571l-87.415 77.699c-1.871 1.665-4.251 2.536-6.66 2.536zm10.029-286.7v254.34l73.575-65.397c1.832-1.636 4.202-2.537 6.66-2.537h106.171z"/></svg>'), none`
            };
        }
        console.log(target)
        return (  
            <React.Fragment>
                <div onContextMenu={this.onContextMenu} style={styles} id="parent" ref="parent" className={`parent-session ${active ? 'active' : ''} || ${success ? 'active' : ''} || ${fail ? 'active' : ''} || ${isFull ? 'active' : ''} || ${quit ? 'popup' : ''}`} >
                    <meta content="width=800, initial-scale=1.0, maximum-scale=1.0" name="viewport" />
                    <div onClick={this.onActive} className={`info`}>
                        <img src="../img/question.svg" alt=""/>
                    </div>
                    <div onClick={this.onQuit} className={`close`}>
                        <img src="../img/close.svg" alt=""/>                        
                    </div>
                    <div id="canvas" className={'canvas'} style={{width:width,height:height,backgroundImage: `url(${client_web_api_url+image_url})`}}>
                        {search_symbols &&
                            search_symbols.map((element,index)=>(
                                <div key={index}>
                                {element.coords.map((coord,i)=>
                                    target==='click' ?
                                        <img className={'search-symbols'} key={i} alt="" src={client_web_api_url+element.url} onClick={this.onSearchSymbolClick.bind(this,element.replacement_url, index, i)} style={{top:coord.y,left:coord.x,position:'absolute',height:symbol_size,width:symbol_size}}/>
                                        :
                                        <img className={'search-symbols'} key={i} alt="" src={client_web_api_url+element.url} onMouseMove={this.onSearchSymbolClick.bind(this,element.replacement_url, index, i)} style={{top:coord.y,left:coord.x,position:'absolute',height:symbol_size,width:symbol_size}}/>
                                )}
                                </div>
                            ))
                        }
                        {
                            distractor_symbols.map((element,index)=>(
                                <div key={index}>
                                    {element.coords.map((coord,i)=>
                                        <img key={i} alt="" src={client_web_api_url+element.url} style={{top:coord.y,left:coord.x,position:'absolute',height:symbol_size,width:symbol_size}}/>
                                    )}
                                </div>
                            ))
                        }
                    </div>
                    <div className={`${active ? 'popup' : ''} || ${success ? 'popup' : ''} || ${fail ? 'popup' : ''} || ${isFull ? 'popup' : ''} || ${quit ? 'popup' : ''}`}>
                        <InfoElement onActive={this.onActive} active={active} data={data} />
                        <Success onSucces={this.onSucces} active={success}/>
                        <Fail onFail={this.onFail} active={fail}/>
                        <Quit onQuit={this.onQuit} onQuitGame={this.onQuitGame} active={quit}/>
                        {isFull &&
                            <FullScreen onFirefox={this.onFirefox} active={isFull} />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Question;

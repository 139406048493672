import http from './httpService';

const { REACT_APP_WEB_API_URL: web_api_url, REACT_APP_TOKEN_NAME: tokenName } = process.env;

const multipartHeader = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

http.setJwt(getJwt());

export function getJwt() {
    return localStorage.getItem(tokenName)
}

export function add(user) {
    let data = new FormData();
    data.append('first_name', user.firstName);
    data.append('last_name', user.lastName);
    data.append('email', user.email);
    data.append('street', user.street);
    data.append('house_number', user.houseNumber);
    data.append('zip_code', user.zipCode);
    data.append('city', user.city);
    data.append('password', user.password);
    data.append('version', user.version);

    return http.post(`${web_api_url}/user`, data, multipartHeader);
}

export function editCurrentUser(user) {
    let data = new FormData();

    data.append('id', user.id);
    data.append('first_name', user.firstName);
    data.append('last_name', user.lastName);
    data.append('email', user.email);
    data.append('street', user.street);
    data.append('house_number', user.houseNumber);
    data.append('zip_code', user.zipCode);
    data.append('city', user.city);
    data.append('password', user.password);

    return http.put(`${web_api_url}/user-edit`, data);
}

export function editCurrentPass(old_password, password) {
    let data = new FormData();

    data.append('old_password', old_password);
    data.append('password', password);


    return http.put(`${web_api_url}/change-password`, data);
}

export function autoUpload(profile_picture) {
    let data = new FormData();

    if (profile_picture) {
        data.append('profile_picture', profile_picture, profile_picture.name);
    }
    return http.put(`${web_api_url}/profile-picture`, data, multipartHeader);
}

export function getCurrentUser(token) {
    return http.get(`${web_api_url}/user`, { token });
}

export async function deleteCurrentUser(token) {
    return await http.delete(`${web_api_url}/user`, { token })
}

export function darkMode(dark_mode) {
    return http.post(`${web_api_url}/dark-mode`, {dark_mode});
}

export function getDarkMode(dark_mode) {
    return http.get(`${web_api_url}/dark-mode`, { dark_mode });
}

export function language(language) {
    return http.post(`${web_api_url}/language`, {language});
}

export function getLanguage(language) {
    return http.get(`${web_api_url}/language`, { language });
}

export default {
    add,
    getCurrentUser,
    editCurrentUser,
    deleteCurrentUser,
    autoUpload,
    editCurrentPass,
    darkMode,
    getDarkMode,
    language,
    getLanguage
}
import React from 'react';
import { withRouter } from 'react-router';
import Form from '../../../../all/common/form';
import sessingSessionService from '../../../../services/settingSessionService';
import * as toast from '../../../../all/toast'
import Joi from 'joi-browser';
import { getLanguage } from '../../../../global/language'

class Standard extends Form {
    state = {
        data: {
            version: 'standard',
            difficulty: 'easy',
            target: 'click',
            search_mode: 'random',
            search_set: 'letters',
            symbol_size: 30
        },
        firefox: false,
        errors: {}
    }

    schema = {
        difficulty: Joi.string().required(),
        target: Joi.string().required(),
        search_mode: Joi.string().required(),
        search_set: Joi.string().required(),
    }

    onFirefox = () => {
        // Firefox 1.0+
        const { data } = this.state;
        var isFirefox = typeof InstallTrigger !== 'undefined';

        if (!!isFirefox) {
            document.documentElement.mozRequestFullScreen();
        }

        // Safari 30+ && Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        if (!!isChrome || navigator.userAgent.indexOf("Safari") !== -1) {
            document.documentElement.webkitRequestFullscreen();
        }
        this.props.history.push({
            pathname: '/session1',
            state: {
                difficulty: data.difficulty,
                version: data.version,
                target: data.target,
                currentTab: this.props.currentTab,
                symbol_size: data.symbol_size
            }
        })
        this.setState({
            firefox: !this.state.firefox
        })
        const { professionalVersion, trainingVersion } = this.props;
        sessingSessionService.changeSettings( data, professionalVersion, trainingVersion ).then(({ data: response }) => {   
        }).catch(err => {
            toast.error(`${getLanguage().error_}`)
        })
    }

    submitForm = () => {
        const { data } = this.state;
        this.setState({
            loading: true
        })
        sessingSessionService.settingSession(data.difficulty, data.version).then(({ data: response }) => {
            this.setState({
                loading: false
            })
            this.props.history.push('/session1')
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    difficultyChange = (e) => {
        const { data } = this.state;
        data.difficulty = e.target.value;
        this.setState({
            data
        });
    }

    targetChange = (e) => {
        const { data } = this.state;
        data.target = e.target.value;
        this.setState({
            data
        });
    }

    searchChange = (e) => {
        const { data } = this.state;
        data.search_mode = e.target.value;
        this.setState({
            data
        });
    }

    search_setChange = (e) => {
        const { data } = this.state;
        data.search_set = e.target.value;
        this.setState({
            data
        });
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps)
        {
            this.setState({
                data: {
                    version: 'standard',
                    difficulty: nextProps.settings.difficulty ,
                    target: nextProps.settings.target ,
                    search_mode: nextProps.settings.search_mode ,
                    search_set: 'letters',
                    symbol_size: 30
                }
            })
        }
    }
    
    render() {
        const { data } = this.state;

        const { difficulty, target, search_mode } = data;

        return (
            <React.Fragment>
                <div className="settings">
                    <form onSubmit={this.handleSubmit} >
                        <div className="checkbox customized settings-session">
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().difficulty_select}</span>
                                <div className="checkbox">
                                    <select name="difficulty" className="text-change" id="difficulty" value={difficulty} onChange={this.difficultyChange}>
                                        <option value="easy">{getLanguage().easy}</option>
                                        <option value="medium">{getLanguage().medium}</option>
                                        <option value="hard">{getLanguage().hard}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().target_select}</span>
                                <select name="target" className="text-change" id="target" value={target} onChange={this.targetChange}>
                                    <option value="click">{getLanguage().click_}</option>
                                    <option value="mouse">{getLanguage().pointer}</option>
                                </select>
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().search_mode}</span>
                                <select name="search_mode" className="text-change" id="search_mode" value={search_mode} onChange={this.searchChange}>
                                    <option value="random">{getLanguage().random}</option>
                                    <option value="always">{getLanguage().same}</option>
                                </select>
                            </div>
                            <span className="text-change" onClick={this.onSaveChanges}>Save Changes</span>
                            <button type="button" className="text-change" onClick={this.onFirefox} >{getLanguage().start}</button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Standard);
import React, { Component } from 'react';
import RenderComponent from '../sections/render-component';
import ProgressSection from '../sections/progressection';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import ProgressElement from './progressElement';

class Progress extends Component {
    state = {};
    render() { 
        return ( 
        <RenderComponent>
            <LeftMenuSection>
                <LeftMenu/>
            </LeftMenuSection>
            <ProgressSection>
                <ProgressElement />
            </ProgressSection>
        </RenderComponent>
         );
    }
}
 
export default Progress;
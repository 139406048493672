import React, { Component } from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

class RightPart extends Component {
    state = {  }
    render() { 
        return ( 
            <Slider autoplay={2000} infinite={true} >
                <img src="../img/vc1.jpg" alt="alt"/>
                <img src="../img/vc2.jpg" alt="alt"/>
                <img src="../img/vc3.jpg" alt="alt"/>
            </Slider>
         );
    }
}
 
export default RightPart;
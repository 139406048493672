import React, {Component} from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getLanguage } from '../../global/language';
import auth from '../../services/authService';
import * as toast from '../../all/toast';
const user = auth.getCurrentUser();
class Dashboard extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            x: '',
            y: '',
            display: '',
            position: ''
        }
    }
    onClickProfessional = () => {
        if(user.subscription === 'Starter')
        {
            auth.startTrial( ).then(({ data }) => {
                auth.getPath(this, user);
            }).catch(err => {
                toast.error(`${getLanguage().error_}`)
            })
        }else{
            auth.getPath(this, user);
        }
    }

    render(){
        // const { x, y, display, position } = this.state;
        return(
            <div className="dashboard">
                {user.subscription === 'Starter' ?
                    <div className={`tooltip`}>
                        <span className="dashboardButton text-change" onClick={this.onClickProfessional}>{getLanguage().start_free_trial}</span>
                        <span className="tooltip-span">{getLanguage().free_trial_tooltip}</span>
                    </div>
                        :
                    <div className={`tooltip`}>
                        <span className="dashboardButton text-change" onClick={this.onClickProfessional}>{getLanguage().free_trial}</span>
                        <span className="tooltip-span">{getLanguage().free_trial_tooltip}</span>
                    </div>
                }
               {/* <Link className="dashboardButton" to="/professional">{getLanguage().free_trial}</Link> */}
               <span className={`text-change`}>{getLanguage().or}</span>
                
               <div className={`tooltip`}>
                    <Link className="dashboardButton text-change" to="/update">{getLanguage().upgrade_full}</Link>
                    <span className="tooltip-span">{getLanguage().upgrade_trial}</span>
                </div>
            </div>
        );
    }
}
 
export default withRouter(Dashboard);
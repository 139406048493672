import React, { Component } from 'react';
import { getLanguage } from '../../global/language'

class Quit extends Component {
    state = { 
        active: false
     }

    render() { 
        const { active, onQuit, onQuitGame } = this.props;
        return ( 
            <React.Fragment>
                <div className={`info-parent ${active ? 'active' : ''}`}>
                    <div className="image">
                        <img src="../img/fail.svg" alt=""/>
                    </div>
                    <div className="content">
                        <span>{getLanguage().end_training}</span>
                        <div className={`buttons`}>
                            <button onClick={onQuitGame}>{getLanguage().yes}</button>
                            <button onClick={onQuit}>{getLanguage().no}</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default Quit;
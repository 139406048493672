import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ChartOne from './chart-one';
import ChartTwo from './chart-two';
import { getLanguage } from '../../global/language';

class ProgressElement extends Component {
        
    render() { 
        
        return ( 
            <React.Fragment>
                <div className="progress">
                    <span className="title text-change">{getLanguage().progress}</span>
                    <div className="progress-charts">
                        <ChartOne />
                        <ChartTwo />
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(ProgressElement);
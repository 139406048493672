import React from 'react';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import RightPart from './right-part';
import authService from '../../services/authService'

class ConfirmEmail extends Form
{
    constructor(props) {
        super(props);
        this.state = {
            data: {
                token: props.match.params.token
            }
        }
    }
   

    schema = {
        token: Joi.string().required()
    }

    componentDidMount(){
        const {token} = this.state.data
        authService.confirmEmailLogin(token).then(( data ) => {
            if(data.success){
                window.location = '/edit-user-profile';
            }
        });
    }

    render()
    {
        return(
            <section className={`parent`}>
                <div className={`login login-wrapper`}>   
                    <div className={`left-part`}>
                        <div className={`logo`}>
                            <img src="../img/logo.svg" alt="alt"/>                                
                        </div>
                        <form onSubmit={this.handleSubmit} className="login-form">
                            <span className="title-form">Confirm Email</span>
                        </form>
                        {/* <div className="go-to-homePage">
                            <Link to="">&#8592; Back to HomePage</Link> 
                        </div> */}
                    </div>
                    <div className={`right-part`}>
                        <RightPart />
                    </div>
                </div>
            </section>
        )
    }
}

export default ConfirmEmail;
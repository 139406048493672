import React, { Component } from 'react';
import DashBoardSection from './dashboardsection';
import UpgradeSection from './upgradesection';
import SessionSection from './sessionsection';
import ProfessionalSection from './professionalsection';
import SettingsSection from './settingssection';
import ProfileSection from './profilesection';
import ProgressSection from './progressection';
import CustomizedSection from './customizedsection';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import auth from '../../services/authService'
import { getLanguage } from '../../global/language'

class RenderComponent extends Component {
    state = {
        active: false
    }

    onActive = () => {
        document.body.classList.toggle('dark');
        this.setState({
            active: !this.state.active
        })
    }

    onClickProfessional = () => {
        this.props.history.push({
            pathname: '/professional',
            state: {
                currentTab: 'professional'
            }
        })  
    }

    render() {
        const user = auth.getCurrentUser();
        let subscription = user.subscription;
        // const { active } = this.state;
        // let link = this.props.children[1].type.name
        return (
            <React.Fragment>
                    <div className={`right-part`}>
                        <div className="topOval" ></div>
                        <div className="rightOval" ></div>
                        <div className="leftOval" ></div>
                        {this.props.children.find(component => component.type === DashBoardSection)}
                        {this.props.children.find(component => component.type === SessionSection)}
                        {this.props.children.find(component => component.type === UpgradeSection)}
                        {this.props.children.find(component => component.type === ProfileSection)}
                        {this.props.children.find(component => component.type === ProgressSection)}
                        {this.props.children.find(component => component.type === SettingsSection)}
                        {this.props.children.find(component => component.type === CustomizedSection)}
                        {this.props.children.find(component => component.type === ProfessionalSection)}

                        {subscription !== "Inactive" && this.props.children[1].type.name !== 'DashBoardSection' && this.props.children[1].type.name !== 'ProfessionalSection' && 
                            <div className="backButton">
                                {subscription === 'Paid' || subscription === 'Partner' || subscription === 'Trial' ?
                                    <span className="text-change" onClick={this.onClickProfessional} >← {getLanguage().back_to}</span>
                                    :
                                    <Link className="text-change" to={`/`}>← {getLanguage().back_to}</Link>
                                }
                                {/* {window.location.pathname === '/professional' || window.location.pathname === '/predefined' ?
                                    <Link to={`/session`}>← Back to Session</Link>
                                    :
                                    <Link to={`/`}>← Back to Dashboard</Link>
                                } */}
                            </div>
                        }
                    </div>
            </React.Fragment>
        );
    }
}
export default withRouter(RenderComponent);
import React from 'react';
import Hermianopia from '../hemianopia/hermianopia';
import HermianopiaKids from '../kids/hermianopia-kids';
import RpVersion from '../rp/rp';
import { withRouter } from 'react-router';
import Form from '../../../../all/common/form';
import Joi from 'joi-browser';
import CustomizedElement from '../../../customized/customizedElement';
import { getLanguage } from '../../../../global/language'
import settingSessionService from '../../../../services/settingSessionService';

class ProfessionalElement extends Form {
    constructor(props)
    {
        super(props);
        this.state = {
            data: {
                version: 'hermianopia',
                training: 'standard',
                currentTab: 'professional'
            },
            settings: {},
            errors: {}
        }
    }
    
    schema = {
        version: Joi.string().required(),
        training: Joi.string().required(),
    }

    componentDidMount() {
        const { data } = this.state;
        settingSessionService.changeSettingsGet().then(({ data: response }) => {
            console.log(response.version)
            data.version = response.version;
            this.setState({
                settings: response,
                data
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }
    
    render() {
        const { errors, data, currentTab, settings } = this.state;
        const { version } = data;
        console.log(settings)
        console.log(version)
        return (
            <React.Fragment>
                <div className="settings">
                <span className="first-title text-change">{getLanguage().select_version}</span>
                    <form className="choose-prof">
                        <div className="checkbox">
                            <label className="checkbox-inside text-change">Hermianopia
                                {this.renderRadioButton(version === 'hermianopia', "version", 'hermianopia', this.handleChange, 'hermianopia', errors.version, false, "version")}
                                <span className="checkmark"></span>
                            </label>
                            <label className="checkbox-inside text-change">Hermianopia Kids
                                {this.renderRadioButton(version === 'hermianopia-kids', "version", 'hermianopia-kids', this.handleChange, 'hermianopia-kids', errors.version, false, "version")}
                                <span className="checkmark"></span>
                            </label>
                            <label className="checkbox-inside text-change">RP Version
                                {this.renderRadioButton(version === 'rp-version', "version", 'rp-version', this.handleChange, 'rp-version', errors.version, false, "version")}
                                <span className="checkmark"></span>
                            </label>
                            <label className="checkbox-inside text-change">Customized Element
                                {this.renderRadioButton(version === 'customized', "version", 'customized', this.handleChange, 'customized', errors.version, false, "version")}
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </form>
                    <div className={`hermianopia ${version === "hermianopia" ? 'active' : 'disable'}`}>
                        <Hermianopia 
                            settings={settings}
                            professionalVersion={version}
                            currentTab={currentTab}
                        />
                    </div>
                    <div className={`hermianopia-kids ${version === "hermianopia-kids" ? 'active' : 'disable'}`}>
                        <HermianopiaKids
                            settings={settings}
                            professionalVersion={version}
                            currentTab={currentTab}
                        />
                    </div>
                    <div className={`rp-version ${version === "rp-version" ? 'active' : 'disable'}`}>
                        <RpVersion
                            settings={settings}
                            professionalVersion={version}
                            currentTab={currentTab}
                        />
                    </div>
                    <div className={`customized-element ${version === "customized" ? 'active' : 'disable'}`}>
                        <CustomizedElement 
                            settings={settings}
                            professionalVersion={version}
                            currentTab={currentTab}
                        />
                    </div>
                    {/* <div className={`sailency ${training === 'saliency' ? 'active' : 'disable'}`}>
                        <Sailency />
                    </div> */}
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(ProfessionalElement);

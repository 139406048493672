import React from 'react';
import { withRouter } from 'react-router';
import Form from '../../../../all/common/form';
import sessingSessionService from '../../../../services/settingSessionService';
import * as toast from '../../../../all/toast'
import Joi from 'joi-browser';
import { SketchPicker } from 'react-color';
import { getLanguage } from '../../../../global/language'
import Monkey from './monkey';

class Standard extends Form {
    state = {
        data: {
            version: 'rp',
            difficulty: 'easy',
            target: 'click',
            search_mode: 'random',
            search_set: 'single_letters',
            font_size: '30',
            font_color: 'ddd',
            background_color: 'f00',
            mouse_cursor: 'normal',
            cursor_color: {
                r:111,
                g:111,
                b:111,
                a:1
            },         
        },
        firefox: false,
        errors: {},
        FontColor: false,
        BackgroundColor: false,
        CursorColor: false,
        background_target: '',
        font_color_target: ''
    }

    schema = {
        difficulty: Joi.string().required(),
        target: Joi.string().required(),
        search_mode: Joi.string().required(),
        search_set: Joi.string().required(),
        font_size: Joi.string().required(),
    }

    onFirefox = () => {
        // Firefox 1.0+
        const {data} = this.state;
        var isFirefox = typeof InstallTrigger !== 'undefined';
        
        if(!!isFirefox)
        {
            document.documentElement.mozRequestFullScreen();
        }

        // Safari 30+ && Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        if(!!isChrome || navigator.userAgent.indexOf("Safari") !== -1)
        {
            document.documentElement.webkitRequestFullscreen();
        }
        this.props.history.push({
            pathname: '/session1',
            state: { 
                currentTab: this.props.currentTab,
                difficulty: data.difficulty,
                version: data.version,
                target: data.target,
                search_mode: data.search_mode,
                search_set: data.search_set,
                symbol_size: parseInt(data.font_size),
                font_color: data.font_color,
                background_color: data.background_color,
                mouse_cursor: data.mouse_cursor,
                cursor_color: data.cursor_color, 
            }
        })  
        // this.setState({
        //     firefox: !this.state.firefox
        // })
        const { professionalVersion, trainingVersion } = this.props;
        sessingSessionService.changeSettings( data, professionalVersion, trainingVersion ).then(({ data: response }) => {   
        }).catch(err => {
            toast.error(`${getLanguage().error_}`)
        })
        this.state.firefox = !this.state.firefox;
    }
    
    submitForm = () => {
        const { data }=this.state;
        this.setState({
            loading: true
        })
        sessingSessionService.settingSession( data.difficulty, data.version ).then(({ data: response }) => {
            this.setState({
                loading: false
            })
        this.props.history.push('/session1')         
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }
    
    difficultyChange = (e) => {
        const {data} = this.state;
        data.difficulty = e.target.value;
        this.setState({
            data
        });
    }

    targetChange = (e) => {
        const {data} = this.state;
        data.target = e.target.value;
        this.setState({
            data
        });
    }
    
    searchChange = (e) => {
        const {data} = this.state;
        data.search_mode = e.target.value;
        this.setState({
            data
        });
    }

    search_setChange = (e) => {
        const {data} = this.state;
        data.search_set = e.target.value;
        this.setState({
            data
        });
    }

    cursorChange = (e) => {
        const {data} = this.state;
        data.mouse_cursor = e.target.value;
        this.setState({
            data
        });
    }

    handleClickFontColor = () => {
        this.setState({ FontColor: !this.state.FontColor })
    };
    
    handleCloseFontColor = () => {
        this.setState({ FontColor: false })
    };
    handleChangeFontColor = (color) => {
        const { data } = this.state;
        data.font_color = color.hex.split('#')[1];
        const value = color.hex
        this.setState({
            data,
            font_color_target: value
        })
    };
    // BG COLOR
    handleClickBackgroundColor = () => {
        this.setState({ BackgroundColor: !this.state.BackgroundColor })
    };

    handleCloseBackgroundColor = () => {
        this.setState({ BackgroundColor: false })
    };
    handleChangeBackgroundColor = (color) => {
        const { data } = this.state;
        data.background_color = color.hex.split('#')[1];
        const value = color.hex
        this.setState({
            data,
            background_target: value
        })
    };
    // Cursor COLOR
    handleClickCursorColor = () => {
        this.setState({ CursorColor: !this.state.CursorColor })
    };

    handleCloseCursorColor = () => {
        this.setState({ CursorColor: false })
    };
    handleChangeCursorColor = (color) => {
        const { data } = this.state;
        data.cursor_color = color.rgb;
        this.setState({ data });
    };

    componentWillReceiveProps(nextProps)
    {
        console.log(nextProps)
        if(nextProps.settings.cursor_color)
        {
            this.setState({
                data:{
                    version: 'rp',
                    difficulty: nextProps.settings.difficulty,
                    target: nextProps.settings.target,
                    search_mode: nextProps.settings.search_mode,
                    search_set: nextProps.settings.search_set,
                    font_size: nextProps.settings.font_size,
                    font_color: nextProps.settings.font_color,
                    background_color: nextProps.settings.background_color,
                    mouse_cursor: nextProps.settings.mouse_cursor,
                    cursor_color: nextProps.settings.cursor_color,
                }
            })
        }
    }
    
    render() { 
        const { data, errors, background_target, font_color_target } = this.state;
        console.log(data)
        const { difficulty, target, search_mode, search_set, font_size, font_color, background_color, mouse_cursor, cursor_color} = data;
        const { r,g,b,a } = cursor_color;
        const pStyle = {
            fontSize: font_size + 'px',
            color: `#${font_color}`
        }
        let size = '20px';
        let styles = {};
        if(mouse_cursor && mouse_cursor === 'big')
        {
            size = '55px'
            styles = {
                backgroundColor: `#${background_color}`
            }
        }
        if(cursor_color){
            const { r,g,b,a } = cursor_color
            styles = {
                backgroundColor: `#${background_color}`,
                cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 320.943 320.943" height="${size}" viewBox="0 0 320.943 320.943" width="${size}" fill="rgba(${ r }, ${ g }, ${ b }, ${ a })" ><path d="m50.147 320.943c-1.391 0-2.791-.284-4.104-.882-3.604-1.616-5.926-5.201-5.926-9.148v-300.881c0-4.055 2.439-7.718 6.19-9.265 3.751-1.557 8.071-.695 10.93 2.174l220.647 220.647c2.87 2.87 3.732 7.179 2.174 10.93-1.548 3.751-5.211 6.19-9.265 6.19h-126.571l-87.415 77.699c-1.871 1.665-4.251 2.536-6.66 2.536zm10.029-286.7v254.34l73.575-65.397c1.832-1.636 4.202-2.537 6.66-2.537h106.171z"/></svg>'), none`
            };
        }
        return ( 
            <React.Fragment>
                <div className="settings">
                    <form onSubmit={this.handleSubmit} >
                        <div className="checkbox customized settings-session">
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().difficulty_select}</span>
                                <div className="checkbox">
                                    <select className="text-change" name="difficulty" id="difficulty" value={difficulty} onChange={this.difficultyChange}>
                                        <option value="easy">{getLanguage().easy}</option>
                                        <option value="medium">{getLanguage().medium}</option>
                                        <option value="hard">{getLanguage().hard}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().target_select}</span>
                                <select className="text-change" name="target" id="target" value={target} onChange={this.targetChange}>
                                    <option value="click">{getLanguage().click_}</option>
                                    <option value="mouse">{getLanguage().pointer}</option>
                                </select>
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().search_mode}</span>
                                <select className="text-change" name="search_mode" id="search_mode" value={search_mode} onChange={this.searchChange}>
                                    <option value="random">{getLanguage().random}</option>
                                    <option value="always">{getLanguage().same}</option>
                                </select>
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().target_search}</span>
                                <div className="checkbox">
                                    <select className="text-change" name="search_set" id="search_set" value={search_set} onChange={this.search_setChange}>
                                        <option value="single_letters">{getLanguage().letters_digits}</option>
                                        <option value="animals">{getLanguage().form_anim}</option>
                                    </select>
                                </div>
                            </div>
                            {this.renderInput("font_size", null, 'font_size', font_size, this.handleChange, errors.font_size, false,`${getLanguage().font_size}`, false, 'number')}
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().font_color}</span>
                                <div className="swatch" onClick={ this.handleClickFontColor }>
                                    <div className="color" style={{ background: `#${font_color}`}} />
                                </div>
                                { this.state.FontColor ? <div className="popover">
                                <div className="cover" onClick={ this.handleCloseFontColor }/>
                                <SketchPicker color={ font_color } onChange={ this.handleChangeFontColor } />
                                </div> : null }
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().background_color}</span>
                                <div className="swatch" onClick={ this.handleClickBackgroundColor }>
                                    <div className="color" style={{ background: `#${background_color}`}} />
                                </div>
                                { this.state.BackgroundColor ? <div className="popover">
                                <div className="cover" onClick={ this.handleCloseBackgroundColor }/>
                                <SketchPicker color={ background_color } onChange={ this.handleChangeBackgroundColor } />
                                </div> : null }
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().mouse_cursor}</span>
                                <select className="text-change" name="mouse_cursor" id="mouse_cursor" value={mouse_cursor} onChange={this.cursorChange}>
                                    <option value="normal">Normal</option>
                                    <option value="big">{getLanguage().big}</option>
                                </select>
                            </div>
                            <div className="input-field">
                                <span className="second-title text-change">{getLanguage().cursor_color}</span>
                                <div className="swatch" onClick={ this.handleClickCursorColor }>
                                    <div className="color" 
                                    style={{
                                        background: `rgba(${ r }, ${ g }, ${ b }, ${ a })`
                                     }} 
                                    />
                                </div>
                                { this.state.CursorColor ? <div className="popover">
                                <div className="cover" onClick={ this.handleCloseCursorColor }/>
                                <SketchPicker color={ cursor_color } onChange={ this.handleChangeCursorColor } />
                                </div> : null }
                            </div>
                            <button type="button" onClick={this.onFirefox} className="text-change" >{getLanguage().start}</button>
                        </div>
                        <div className={`target-preview`}>
                            <span>Targer Preview</span>
                            <div className={`target-box`} style={styles}>
                                {search_set === 'single_letters' ?
                                    <p style={pStyle} >A1</p>
                                    :
                                    <Monkey 
                                        font_size={font_size}
                                        font_color_target={font_color_target}
                                    />
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(Standard);
import React, { Component } from 'react';
import { getLanguage } from '../../global/language'

const {
    REACT_APP_WEB_API_URL_CLIENT: client_web_api_url
} = process.env;

class InfoElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            data: { search_symbol_url: '' },
        }
        this.state.data=props.data
    }
    render() { 
        const { data } = this.state;
        const { active, onActive } = this.props;
        const { search_symbols } = data;
        return ( 
            <React.Fragment>
                <div className={`info-parent ${active ? 'active' : ''}`}>
                    <div className="image">
                        <img src="../img/done.svg" alt=""/>
                    </div>
                    <div className="content">
                        {search_symbols[0]&&
                            <img src={client_web_api_url+search_symbols[0].url} alt=""/>
                        }
                        <span>{getLanguage().search_symbols}</span>
                        <button onClick={onActive}>{getLanguage().got_it}</button>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default InfoElement;
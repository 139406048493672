import React from 'react';
import { withRouter } from 'react-router';
import Form from '../../../../all/common/form';
import sessingSessionService from '../../../../services/settingSessionService';
import * as toast from '../../../../all/toast'
import Joi from 'joi-browser';
import { getLanguage } from '../../../../global/language'

class Sailency extends Form {
    constructor(props)
    {
        super(props);
        this.state = {
            data: {
                version:'saliency',
                difficulty: 'easy',
                target: 'click',
                mouse_cursor: 'normal',
                scene: 'nature',
                symbol_size: 30
            },
            firefox: false,
            errors: {}        
        }
    }

    schema = {
        difficulty: Joi.string().required(),
        version: Joi.string().required(),
        target: Joi.string().required(),
        mouse_cursor: Joi.string().required(),
        scene: Joi.string().required(),
    }

    onFirefox = () => {
        // Firefox 1.0+
        const {data} = this.state;
        var isFirefox = typeof InstallTrigger !== 'undefined';
        
        if(!!isFirefox)
        {
            document.documentElement.mozRequestFullScreen();
        }

        // Safari 30+ && Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        if(!!isChrome || navigator.userAgent.indexOf("Safari") !== -1)
        {
            document.documentElement.webkitRequestFullscreen();
        }
        this.props.history.push({
            pathname: '/session1',
            state: { 
                difficulty: data.difficulty,
                version: data.version,
                target:data.target,
                currentTab: this.props.currentTab,
                symbol_size: data.symbol_size
            }
        })  
        // this.setState({
        //     firefox: !this.state.firefox
        // })
        const { professionalVersion, trainingVersion } = this.props;
        sessingSessionService.changeSettings( data, professionalVersion, trainingVersion ).then(({ data: response }) => {   
        }).catch(err => {
            toast.error(`${getLanguage().error_}`)
        })
        this.state.firefox = !this.state.firefox;
    }
    
    submitForm = () => {
        const { data }=this.state;
        this.setState({
            loading: true
        })
        sessingSessionService.settingSession( data.difficulty, data.version ).then(({ data: response }) => {
            this.setState({
                loading: false
            })
        this.props.history.push('/session1')         
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    
    targetChange = (e) => {
        const {data} = this.state;
        data.target = e.target.value;
        this.setState({
            data
        });
    }
    
    mouseChange = (e) => {
        const {data} = this.state;
        data.mouse_cursor = e.target.value;
        this.setState({
            data
        });
    }

    difficultyChange = (e) => {
        const {data} = this.state;
        data.difficulty = e.target.value;
        this.setState({
            data
        });
    }

    sceneChange = (e) => {
        const {data} = this.state;
        data.scene = e.target.value;
        this.setState({
            data
        });
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps)
        {
            this.setState({
                data:{
                    version: 'saliency',
                    difficulty: nextProps.settings.difficulty,
                    target: nextProps.settings.target,
                    mouse_cursor: nextProps.settings.mouse_cursor,
                    scene: nextProps.settings.scene,
                    symbol_size: 30
                } 
            })
        }
    }

    render() { 
        const { data } = this.state;
        const { difficulty, target, mouse_cursor, scene} = data;
        return ( 
            <React.Fragment>
                <form onSubmit={this.handleSubmit} >
                    <div className="checkbox customized settings-session">
                        <div className="input-field">
                            <span className="second-title text-change">{getLanguage().difficulty_select}</span>
                            <div className="checkbox">
                                <select name="difficulty" className="text-change" id="difficulty" value={difficulty} onChange={this.difficultyChange}>
                                    <option value="easy">{getLanguage().easy}</option>
                                    <option value="medium">{getLanguage().medium}</option>
                                    <option value="hard">{getLanguage().hard}</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-field">
                            <span className="second-title text-change">{getLanguage().target_select}</span>
                            <select name="target" className="text-change" id="target" value={target} onChange={this.targetChange}>
                                <option value="click">{getLanguage().click_}</option>
                                <option value="mouse">{getLanguage().pointer}</option>
                            </select>
                        </div>
                        <div className="input-field">
                            <span className="second-title text-change">{getLanguage().mouse_cursor}</span>
                            <select name="mouse_cursor" className="text-change" id="mouse_cursor" value={mouse_cursor} onChange={this.mouseChange}>
                                <option value="normal">Normal</option>
                                <option value="big">{getLanguage().big}</option>
                            </select>
                        </div>
                        <div className={`input-field`}>
                            <span className="second-title text-change">{getLanguage().select_scene}</span>
                            <div className="checkbox">
                                <select name="scene" className="text-change" id="scene" value={scene} onChange={this.sceneChange}>
                                    <option value="nature">{getLanguage().select_nature}</option>
                                    <option value="city">{getLanguage().city}</option>
                                    <option value="ocean">{getLanguage().select_ocean}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <button type="button" onClick={this.onFirefox} className="text-change" >{getLanguage().start}</button>
                </form>
            </React.Fragment>
         );
    }
}
 
export default withRouter(Sailency);
import http from './httpService';
import jwt_decode from 'jwt-decode'
import { getLanguage } from '../global/language'

const {
    REACT_APP_WEB_API_URL: web_api_url,
    REACT_APP_TOKEN_NAME: tokenName
} = process.env;

http.setJwt(getJwt())

export function login(email, password) {
    return http.post(`${web_api_url}/login`, { email, password }).then(({ data }) => {
        if (data.access_token) {
            localStorage.setItem(tokenName, data.access_token);
            return {
                success: true
            };
        }
        else {
            return {
                error: "Something went wrong. Please try again"
            }
        }
    }).catch(err => {
        let error = getLanguage().error_;
        if (err.response && err.response.status === 401) {
            error = getLanguage().email_confirm;
        }else if(err.response && err.response.status === 402){
            error = getLanguage().wrong_email;            
        }
        return {
            error: error
        }
    })
}

export function startTrial() {
    return http.put(`${web_api_url}/start-trial`).then(({ data }) => {
        if (data.access_token) {
            localStorage.setItem(tokenName, data.access_token);
            return {
                success: true
            };
        }
        else {
            return {
                error: getLanguage().error_
            }
        }
    }).catch(err => {
        return {
            error: getLanguage().error_
        }
    })
}

export function confirmEmailLogin(token) {
    return http.post(`${web_api_url}/confirm-email`, { token }).then(({ data }) => {
        if (data.access_token) {
            localStorage.setItem(tokenName, data.access_token);
            return {
                success: true
            };
        }
        else {
            return {
                error: "Email or Password is wrong"
            }
        }
    }).catch(err => {
        let error = "Something went wrong. Please try again";
        if (err.response && err.response.status === 400) {
            error = "Email or Password is wrong";
        }

        return {
            error: error
        }
    })
}

export function logout() {
    localStorage.removeItem(tokenName);
}

export function getJwt() {
    return localStorage.getItem(tokenName)
}

export function getUserId() {
    return getCurrentUser().id;
}

export function getUserIdColor(id) {
    let userid = id;
    if (!userid) userid = getUserId();
    let color = "#" + userid.substr(userid.length - 6, userid.length);
    return color;
}

export function getInitials() {
    let firstName = getCurrentUser().first_initial;
    let lastName = getCurrentUser().last_initial;

    return firstName + lastName;
}

export function getEmail() {
    let email = getCurrentUser().email;
    return email;
}

export function shortTitle(title)
{
    if(title.length > 80)
    {
        let newString = title;
        let shortTitle = newString.substr(0, 80) + '...';
        title=shortTitle;
        return shortTitle;
    }
    return title;
}

export function getCurrentUser() {
    try {
        const token = getJwt();
        const decoded = jwt_decode(token);
        if (new Date().getTime() / 1000 > decoded.exp) {
            //expired
            localStorage.removeItem(tokenName);
            return null;
        }
        return decoded.user_claims;
    } catch (ex) {
        return null;
    }
}

export async function verifyChangePasswordToken(token) {
    return await http.get(`${web_api_url}/verify-token/${token}`);
}

export async function confirmUser(token) {
    return await http.post(`${web_api_url}/confirm-user`, { token });
}

export function getPath(context, user) {
    let version = user.version;
    let path = '';
    if(version === 'VISIOcoach Hemianopia KIDS')
    {
        path = 'hermianopia-kids'
    }
    else if(version === 'VISIOcoach Hemianopia')
    {
        path = 'hermianopia'
    }
    else if(version === 'VISIOcoach Retinitis pigmentosa')
    {
        path = 'rp-version'
    }
    else if(version === 'VISIOcoach professional')
    {
        path = 'professional'
    }
    context.props.history.push({
        pathname: path,
        state: {
            currentTab: path
        }
    })  
}

export default {
    login,
    logout,
    getJwt,
    getCurrentUser,
    verifyChangePasswordToken,
    confirmUser,
    getInitials,
    getEmail,
    confirmEmailLogin,
    startTrial,
    getPath
}

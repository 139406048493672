import React from 'react';
import languageJson from '../json/languageJson'

export let language = 'en';

export let languageJsonEn = languageJson.en 
export let languageJsonDe = languageJson.de 

export const setLanguage = (selectLanguage) => {
    language = selectLanguage;
}

export const getLanguage = () => {
    if(language === 'en'){
        return languageJsonEn
    }
    else if(language === 'de'){
        return languageJsonDe
    }
    else{
        return null
    }
}

export const onChangeLanguage = (getLanguage1) => {
    language = getLanguage1
}

export const ChangeLanguage = ({onClick}) => {
    return (
        <React.Fragment>
            <div className={`language ${language === 'en' ? 'en' : 'de'}`} onClick={onClick}>
                <span className={`text-change`}>{language}</span>
            </div>
        </React.Fragment>
    )
}

export default {
    languageJsonEn,
    languageJsonDe,
    setLanguage,
    getLanguage,
    ChangeLanguage,
    onChangeLanguage
}


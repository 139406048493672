import React from 'react';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import * as toast from '../../all/toast'
import { Link } from 'react-router-dom'
import { getCurrentUser } from '../../services/authService';
import forgotPasswordService from '../../services/forgotPasswordService';
import RightPart from './right-part';
import { getLanguage } from '../../global/language'

class ForgotPassword extends Form
{
    state = {
        data: {
            email: ''
        },
        errors: {},
        loading: false
    }

    schema = {
        email: Joi.string().email().required()
    }

    componentWillReceiveProps() {
        const { data } = this.state;
        data.password = '';
        this.setState({
            data
        })
    }

    submitForm = () => {
        this.setState({
            loading: true
        });

        const { email } = this.state.data;
        forgotPasswordService.forgotPassword(email).then((data) => {
            if (data.error) {
                toast.error(data.error);

                this.setState({
                    loading: false
                });
            }
            else window.location = '/';
        }).catch(err => {
            this.setState({
                loading: false
            });
        });
    }

    render()
    {
        if (getCurrentUser()) this.props.history.push('/')

        const { data, errors, loading } = this.state;
        const { email } = data;
        
        return ( 
            <React.Fragment>
                <section className={`parent`}>
                    <div className={`login login-wrapper`}>   
                        <div className={`left-part`}>
                            <div className={`logo`}>
                                <img src="../img/logo.svg" alt="alt"/>                                
                            </div>
                            <form onSubmit={this.handleSubmit} className="login-form">
                                <span className="title-form text-change">Account recovery</span>
                                <div className="register-input">
                                    {this.renderInput("email", null, 'email', email, this.handleChange, errors.email, false, getLanguage().email_placeholder)}
                                </div>
                                <div className="loginButton">
                                    {this.renderSubmitButton(getLanguage().next, loading)}
                                </div>
                                <Link className="aButtonOne text-change" to="/login">{getLanguage().back_login}</Link>
                            </form>
                            {/* <div className="go-to-homePage">
                                <Link to="">&#8592; Back to HomePage</Link> 
                            </div> */}
                        </div>
                        <div className={`right-part`}>
                            <RightPart />
                        </div>
                    </div>
                </section>
            </React.Fragment>
         ); 
    }
}
 
export default ForgotPassword;
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom' ;
import './assets/css/App.css';
import './assets/css/mobile.css';
import './assets/css/user_layout.css';
import Home from './components/home/home';
import Upgrade from './components/upgrade/upgrade';
import Session from './components/session/session';
import Login from './components/auth/login';
import ForgotPassword from './components/auth/forgotPassword';
import Register from './components/auth/register';
import Profile from './components/profile/profile';
import Progress from './components/progress/progress';
import Settings from './components/settings/settings';
import Customized from './components/customized/customized';
import Question from './components/question/question';
import { withRouter } from 'react-router';
import ProtectedRoute from '../src/all/common/protected-route';
import Logout from './components/auth/logout';
import { ToastContainer } from 'react-toastify';
import ForgotChangePassword from './components/auth/forgotChangePassword'
import LeftMenu from './components/left-menu'
import auth from '../src/services/authService'
import Professional from '../src/components/settings/versionComponents/professional/professional'
import ConfirmEmail from "./components/auth/confirm_email"
import { ChangeLanguage, onChangeLanguage } from './global/language'
import userService from './services/userService';
import * as toast from '../src/all/toast'
import { getLanguage } from '../src/global/language'
import { placeholder } from '@babel/types';

const user = auth.getCurrentUser();

class App extends Component {
  state = {
      active: false,
      language: 'en',
      value: 0
  }

  componentDidMount()
  {
    if(user)
    {
      userService.getDarkMode().then(({ data: response }) => {
          if(response.dark_mode)
          {
            document.body.classList.toggle('dark');
          }
          this.setState({
            active: response.dark_mode
          })
      }).catch(err => {
          this.setState({
              loading: false
          })
          toast.error(`${getLanguage().error_}`)
      })
      userService.getLanguage().then(({ data: response }) => {  
          onChangeLanguage(response.language);
          this.setState({
            language: response.language
          })
      }).catch(err => {
          this.setState({
              loading: false
          })
          toast.error(`${getLanguage().error_}`)
      })
    }
  }

  onActive = () => {
      if(user)
      {
        document.body.classList.toggle('dark');
        userService.darkMode( !this.state.active ).then(({ data: response }) => {
            this.setState({
                loading: false,
                active: response.dark_mode
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
      }
      else
      {
        document.body.classList.toggle('dark');
        this.setState({
            active: !this.state.active
        })
      }
  }

  onChangeLanguage1 = () => {
    let { language } = this.state;
    let value = '';
    if(language === 'de')
    {
        value = 'en'
    }else {
        value = 'de'
    }
    if(user)
    {
      userService.language( value ).then(({ data: response }) => {
        onChangeLanguage(response.language);
        this.setState({
          language: response.language
        })
      }).catch(err => {
        this.setState({
            loading: false
        })
      })
    }else{
      onChangeLanguage(value);
      this.setState({
        language: value
      })
    }
  }

  onFontChangeUp = () => {
    let spans = document.getElementsByClassName("text-change")
    for(let i = 0; i < spans.length; i++)
    {
      let style = window.getComputedStyle(spans[i], null).getPropertyValue('font-size');
      // window.getComputedStyle(spans[i], null).placeholder.style.fontSize = '50px';
      let currentSize = parseInt(style);
      if((this.state.value + 1) <= 10)
      {
        spans[i].style.fontSize = (currentSize+1) + 'px';
        this.setState({
          value: this.state.value + 1
        })
      }
    }
    let inputs = document.querySelectorAll('input.text-change');
    console.log(inputs)
    for(let i = 0; i < inputs.length; i++)
    {
      let style = window.getComputedStyle(inputs[i], ':placeholder');
      console.log(style)
      // let currentSize = parseInt(style);
      // if((this.state.value + 1) <= 5)
      // {
      //   style.setProperty('font-size', (currentSize+1) + 'px', '') ;
      //   this.setState({
      //     value: this.state.value + 1
      //   })
      // }
    }
  }
  
  onFontChangeDown = () => {
    let spans = document.getElementsByClassName("text-change")
    for(let i = 0; i < spans.length; i++)
    {
      let style = window.getComputedStyle(spans[i], null).getPropertyValue('font-size');
      let currentSize = parseInt(style);
      if((this.state.value - 1) >= -10)
      {
        spans[i].style.fontSize = (currentSize-1) + 'px';
        this.setState({
          value: this.state.value - 1
        })
      }
    }
  }

  getPath = () => {
    let version = user.version;
    let path = '';
    if(version === 'VISIOcoach Hemianopia KIDS')
    {
        path = 'hermianopia-kids'
    }
    else if(version === 'VISIOcoach Hemianopia')
    {
        path = 'hermianopia'
    }
    else if(version === 'VISIOcoach Retinitis pigmentosa')
    {
        path = 'rp-version'
    }
    else if(version === 'VISIOcoach professional')
    {
        path = 'professional'
    }
    return path;
  }

  render() {
    const { active, value } = this.state;
    return (
      <Route render={({ location }) => (
        <React.Fragment>
              <section className={`parent-div`}>
                {location.pathname !== '/session1' && location.pathname !== '/customized-question' &&
                  <React.Fragment>
                    <div className={`darkmode ${active ? 'dark' : ''}`} onClick={this.onActive}>
                      <img className="dark-img" src="../img/moon-dark.svg" alt="alt"/>
                    </div>
                    <div className={`fontSize`}>
                      <img onClick={this.onFontChangeUp} className="dark-img" src="../img/rightArrow.svg" alt="alt"/>                      
                      <span>{value}</span>
                      <img onClick={this.onFontChangeDown} className="dark-img" src="../img/rightArrow.svg" alt="alt"/>
                    </div>                    
                    <ChangeLanguage onClick={this.onChangeLanguage1} />
                  </React.Fragment>
                }
                {auth.getCurrentUser() && location.pathname !== '/session1' && location.pathname !== '/customized-question' &&
                  <LeftMenu />
                }
                <Switch>
                  <Route path='/login' component={Login} />
                  <Route path='/forgot-password' component={ForgotPassword} />
                  <Route path='/change-password/token=:token' component={ForgotChangePassword} />
                  <Route path='/confirm-email/token=:token' component={ConfirmEmail} />
                  <Route path='/register' component={Register} />
                  <Route path="/logout" component={Logout} />
                  <ProtectedRoute roles="Paid,Partner,Trial" path='/profile' component={Profile} />
                  <ProtectedRoute roles="Paid,Partner,Trial" path='/session' component={Session} />
                  <ProtectedRoute roles="Paid,Partner,Trial" path='/session1' component={Question} />
                  <ProtectedRoute roles="Trial" path='/update' component={Upgrade} />
                  {/* <ProtectedRoute roles="Paid,Partner,Trial" path='/predefined' component={Settings} /> */}
                  {user && (user.subscription === 'Paid' || user.subscription === 'Partner') &&
                    <ProtectedRoute roles="Paid,Partner" exact path='/' component={this.getPath() === 'professional' ? Professional : Settings} />
                  }
                  {user && (user.subscription === 'Trial' || user.subscription === 'Starter') &&
                    <ProtectedRoute roles="Trial,Starter" exact path='/' component={Home} />
                  }
                  {user && (user.subscription === 'Trial' || user.subscription === 'Starter') &&
                    <ProtectedRoute roles="Trial" path={'/'+this.getPath()} component={this.getPath() === 'professional' ? Professional : Settings} />
                  }
                  {user && (user.subscription === 'Inactive') &&
                    <ProtectedRoute roles="Inactive" exact path='/' component={Upgrade} />
                  }
                  <ProtectedRoute roles="Paid,Partner,Trial" path='/customized' component={Customized} />
                  <ProtectedRoute roles="Paid,Partner" path='/progress' component={Progress} />
                  {!user &&
                    <Redirect to='/login' />
                  }
                  <Redirect to='/' />
                </Switch>
                <ToastContainer />
              </section>
          </React.Fragment> 
        )} />
    );
  }
}
export default withRouter(App);
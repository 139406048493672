import React from 'react';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import * as toast from '../../all/toast'
import userService from '../../services/userService';
import { getLanguage } from '../../global/language'

class ChangePassword extends Form {
    constructor(props) {
        super(props);
        this.state = { 
            data: {
                oldPassword: '',
                password: ''
            },
            errors: {},
            loading: false,
         }
    }

    schema = {
        oldPassword: Joi.string().required(),
        password: Joi.string().required(),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required()
    }

    submitForm = () => {
        const { data }=this.state;
        this.setState({
            loading: true
        })
        userService.editCurrentPass( data.oldPassword, data.password ).then(({ data: response }) => {
            if(response.success){
                toast.success(`${getLanguage().success_edit_pass}`)
                this.props.onActive();
            }else{
                toast.error(response.error)
            }
            this.setState({
                loading: false
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    render() { 
        const { data, errors, loading } = this.state;
        const { active, onActive } = this.props;

        const { oldPassword, password, confirmPassword } = data;

        return ( 
            <div className={`change-password ${active ? 'active' : ''}`}>
                <img src="../img/close.svg" onClick={onActive} alt=""/>
                <form onSubmit={this.handleSubmit}>
                    <div className="change-password-input">
                        {this.renderInput("oldPassword", null, "oldPassword", oldPassword, this.handleChange, errors.oldPassword, false, `${getLanguage().old_password}`, false, "password")}
                        {this.renderInput("password", null, "password", password, this.handleChange, errors.password, false, `${getLanguage().new_password}`, false, "password")}
                        {this.renderInput("confirmPassword", null, "confirmPassword", confirmPassword, this.handleChange, errors.password, false, `${getLanguage().c_new_password}`, false, "password")}
                        {this.renderSubmitButton(`${getLanguage().save_password}`, loading)}
                    </div>
                </form>
            </div>
         );
    }
}
 
export default ChangePassword;
import http from './httpService';

const { REACT_APP_WEB_API_URL: web_api_url, REACT_APP_TOKEN_NAME: tokenName } = process.env;

http.setJwt(getJwt());

export function getJwt() {
    return localStorage.getItem(tokenName)
}

export async function settingSession( difficulty, scene) {
    return http.get(`${web_api_url}/settings-session/${difficulty}/${scene}`);
}

export async function changeSettings(data, version, trainingVersion) {
    let object=data
    object['version']=version
    object['trainingVersion']=trainingVersion
    return http.put(`${web_api_url}/settings`, {object});
}

export async function changeSettingsGet() {
    return http.get(`${web_api_url}/settings`);
}

export default {
    settingSession,
    changeSettings,
    changeSettingsGet
}
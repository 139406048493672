import React from 'react';
import { Link } from 'react-router-dom';
import authService from "../../src/services/authService";
import userService from "../../src/services/userService";
import Form from '../all/common/form';
import * as toast from '../all/toast';
import Joi from 'joi-browser';
import { getLanguage } from '../global/language'
import { withRouter } from 'react-router';

class LeftMenu extends Form {
    state = {
        data: {
            profile_picture: ''
        },
        firstName: '',
        lastName: '',
        email: '',
        subscription: '',
        profile_pic: [],
        errors: {},
    }

    schema = {
        profile_picture: Joi.array(),
    }

    componentDidMount() {
        userService.getCurrentUser().then(({ data: response }) => {
            this.setState({
                profile_pic : response.profile_picture,
                firstName: response.first_name,
                lastName: response.last_name,
                email: response.email,
                subscription: response.subscription
            })
        }).catch(err => {
            if (err.response && err.response.status === 400) {
                this.props.history.push('/user-profile')
            }
            toast.error(`${getLanguage().refresh_page}`)
        })
    }

    autoUpload = ({currentTarget: input}) => {
        if(input.value[0].type.split('/').includes('image')){
            userService.autoUpload(input.value[0]).then(({ data: response }) => {
                this.setState({
                    profile_pic : response.profile_picture
                })
            }).catch(err => {
                if (err.response && err.response.status === 400) {
                    this.props.history.push('/user-profile')
                }
                toast.error(`${getLanguage().refresh_page}`)
            })
        }
        else{
            toast.error(`${getLanguage().invalid_file}`);
        }
    }

    onClickProfessional = () => {
        authService.getPath(this, authService.getCurrentUser());
    }

    render() { 

        const { profile_pic, data, firstName, lastName, email, subscription } = this.state;
        const { profile_picture }=data;
        
        return ( 
            <div className={`left-menu`}>
                <div className="left-menu-wrapper">
                    <div className={`logo`}>
                        <img src="../img/logo.svg" alt=""/>                                
                    </div>
                    <div className={`menu-user`}>
                        <span className="welcome text-change">{getLanguage().welcome} {firstName}</span>
                        <div className="profile-pricture">
                        {profile_pic &&
                            <img className="profileImg" src={`${profile_pic}`} alt=""/>
                        }
                        {!profile_pic &&
                            <span className="initial">{authService.getInitials()}</span>
                        }
                        <form>
                            {this.renderUploadInput("profile_picture", null, profile_picture, 'image', this.autoUpload, this.state.errors, true, 1, true, "If you want to update image, just upload another one up to 10MB")}
                        </form>
                        </div>
                        <p className={`text-change`}>{firstName} {lastName}</p>
                        <p className={`text-change`}>{email}</p>
                    </div>
                    <div className={`content-menu`}>
                        <div className="tooltip">
                            {subscription === 'Paid' || subscription === 'Partner' ?
                                // <Link className={`abutton ${subscription === 'Inactive' ? 'disable' : ''}`} to="/professional"> <img src="../img/man1.svg" alt=""/>
                                //     {getLanguage().session}
                                //     <span className="tooltip-span">Not avaialble for the unpaid service</span>
                                // </Link>
                                <span onClick={this.onClickProfessional} className={`abutton text-change ${subscription === 'Inactive' ? 'disable' : ''}`}><img src="../img/man1.svg" alt=""/>
                                    {getLanguage().session}
                                    <span className="tooltip-span">Not avaialble for the unpaid service</span>
                                </span>
                                :
                                <Link className={`abutton text-change ${subscription === 'Inactive' ? 'disable' : ''}`} to="/"> <img src="../img/man1.svg" alt=""/>
                                    {getLanguage().session}
                                    <span className="tooltip-span">Not avaialble for the unpaid service</span>
                                </Link>
                            }
                        </div>
                        <div className="tooltip">
                            <Link className={`textbutton text-change ${subscription === 'Inactive' || subscription === 'Trial' || subscription === 'Starter' ? 'disable' : ''}`} to="/progress">
                                <img src="../img/table.svg" alt=""/>
                                {getLanguage().progress}
                                <span className="tooltip-span">Not avaialble for the unpaid service</span>
                            </Link>
                        </div>
                        <div className="tooltip">   
                            <Link className={`textbutton text-change ${subscription === 'Inactive' || subscription === 'Starter' ? 'disable' : ''}`} to="/profile"> <img src="../img/settings.png" alt=""/> 
                            {getLanguage().settings}
                            <span className="tooltip-span">Not avaialble for the unpaid service</span>
                            </Link>
                        </div>
                        <div className="tooltip">
                            <Link className={`textbutton text-change`} to="/logout"> <img src="../img/shape.png" alt=""/>
                            {getLanguage().log_out}                   
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default withRouter(LeftMenu);
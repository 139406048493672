import React, { Component } from 'react';
import RenderComponent from '../sections/render-component';
import SessionSection from '../sections/sessionsection';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import SessionElement from './sessionElement';

class Session extends Component {
    state = {};
    render() { 
        return ( 
        <RenderComponent>
            <LeftMenuSection>
                <LeftMenu/>
            </LeftMenuSection>
            <SessionSection>
                <SessionElement />
            </SessionSection>
        </RenderComponent>
         );
    }
}
 
export default Session;
import React from 'react';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import * as toast from '../../all/toast'
import { getCurrentUser } from '../../services/authService';
import forgotPasswordService from '../../services/forgotPasswordService';
import { getLanguage } from '../../global/language'

class ForgotChangePassword extends Form
{
    constructor(props) {
        super(props);
        this.state = { 
            data: {
                password: '',
                confirmPassword: '',
                token: props.match.params.token,
            },
            errors: {},
            loading: false,
        }
        console.log(this.props)
    }

    schema = {
        password: Joi.string().required(),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
        token: Joi.string().required(),
    }

    componentWillReceiveProps() {
        const { data } = this.state;
        data.password = '';
        this.setState({
            data
        })
    }

    submitForm = () => {
        const { data }=this.state;
        this.setState({
            loading: true
        })
        forgotPasswordService.changePassowrd( data.password, data.token  ).then(({ data: response }) => {
            toast.success(`${getLanguage().success_edit_pass}`)
            this.setState({
                loading: false
            })
            window.location = '/';
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error(`${getLanguage().error_}`)
        })
    }

    render()
    {
        if (getCurrentUser()) this.props.history.push('/')

        const { data, errors, loading } = this.state;
        const { password, confirmPassword } = data;
        
        return ( 
            <React.Fragment>
                <section className={`parent`}>
                    <div className={`login`}>   
                        <div className={`left-part`}>
                            <div className={`logo`}>
                                <img src="../img/logo.svg" alt=""/>                                
                            </div>
                            <form onSubmit={this.handleSubmit} className="login-form">
                                <span className="title-form">Change Password</span>
                                {this.renderInput("password", null, "password", password, this.handleChange, errors.password, false, "New Password", false, "password")}
                                {this.renderInput("confirmPassword", null, "confirmPassword", confirmPassword, this.handleChange, errors.password, false, "Confirm New Password", false, "password")}
                                <div className="loginButton">
                                    {this.renderSubmitButton("Submit", loading)}
                                </div>
                            </form>
                        </div>
                        <div className={`right-part`}>
                            <img src="../img/loginImage.png" alt=""/>
                        </div>
                    </div>
                </section>
            </React.Fragment>
         ); 
    }
}
 
export default ForgotChangePassword;
import React, { Component } from 'react';
import RenderComponent from '../sections/render-component';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import SettingsSection from '../sections/settingssection';
import CustomizedElement from './customizedElement';

class Customized extends Component {
    state = {};
    render() { 
        return ( 
        <RenderComponent>
            <LeftMenuSection>
                <LeftMenu/>
            </LeftMenuSection>
            <SettingsSection>
                <CustomizedElement />
            </SettingsSection>
        </RenderComponent>
         );
    }
}
 
export default Customized;
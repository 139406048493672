import React from 'react';
import Joi from 'joi-browser';
import Form from '../../all/common/form';
import * as toast from '../../all/toast'
import userService from '../../services/userService';
import { getCurrentUser } from '../../services/authService';
import { Link } from 'react-router-dom';
import RightPart from './right-part';
import { getLanguage } from '../../global/language'

class Register extends Form {
    state = {
        data: {
            firstName: '',
            lastName: '',
            email: '',
            repeatEmail: '',
            password: '',
            confirmPassword: '',
            street: '',
            zipCode: '',
            city: '',
            houseNumber: '',
            version: '',
        },
        errors: {},
        loading: false
    }

    schema = {
        firstName: Joi.string().required(),
        lastName: Joi.string().required(),
        email: Joi.string().email().required(),
        repeatEmail: Joi.string().email().required(),
        password: Joi.string().required(),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
        street: Joi.string().required(),
        houseNumber: Joi.number().required(),
        zipCode: Joi.string().required(),
        city: Joi.string().required(),
        version: Joi.string().required()
    }

    componentWillReceiveProps() {
        const { data } = this.state;
        data.password = '';
        data.confirmPassword = '';
        this.setState({
            data
        })
    }

    handleConfirmPasswordChange = ({ currentTarget: input }) => {
        const schema = {
            password: Joi.string().required(),
            confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
        }
        let fieldName = input.name;

        const { data, errors } = this.state;
        const { password, confirmPassword } = data;

        let obj;

        if (fieldName === 'password') {
            obj = {
                password: input.value,
                confirmPassword
            }
            data.password = input.value;
        }
        else {
            obj = {
                password,
                confirmPassword: input.value
            }
            data.confirmPassword = input.value;
        }

        const { error } = Joi.validate(obj, schema);
        delete errors['confirmPassword'];
        delete errors['password'];
        if (!error) {
            // data.passwordValid = true;
        }
        else {
            // data.passwordValid = false;
            error.details.forEach(err => {
                errors[err.path[0]] = 'error';
            })
        }
        data[input.name] = input.value;

        this.setState({
            data,
            errors
        })
    }

    submitForm = () => {
        this.setState({
            loading: true
        })
        const { firstName, lastName, email, password, street, houseNumber, zipCode, city, version } = this.state.data;
        const user = {
            firstName,
            lastName,
            email,
            password,
            street,
            houseNumber,
            zipCode,
            city,
            version
        }
        userService.add(user).then(({ data }) => {
            window.location = '/';
        }).catch(err => {
            if(err.response.status === 401){
                toast.error(getLanguage().email_exists);
            }
            else{
                toast.error(getLanguage().error_);
            }
            console.log(err.response)
            this.setState({
                loading: false
            })
        })
    }
    
    onDropDownClickError = (e) => {
        const elements=document.getElementsByClassName("invalid-tooltip");
        
        if (!e.target.parentNode.parentNode.className.includes('invalid-tooltip')) {
            if(elements[0])
            {
                elements[0].classList.remove("invalid-tooltip");
            }
            e.target.parentNode.parentNode.className += ' invalid-tooltip'
        }else {
            e.target.parentNode.parentNode.className = 'input-field input-control is-invalid'
        }
    }

    render() { 
        
        if (getCurrentUser()) this.props.history.push('/')
        
        const { data, errors } = this.state;
        const { firstName, lastName, email, repeatEmail, password, confirmPassword, street, houseNumber, zipCode, city, version } = data;
        const inputClasses = errors.version ? 'input-control is-invalid' : 'input-control';
        const inputWarning = errors.version ? 'is-invalid' : '';    
    
        // if(errors.version)
        // {
        //     document.getElementById('version-drop').classList.add('is-invalid');
        //     document.getElementById('version-drop-warning').classList.add('is-invalid');
        //     console.log(errors.version)
        // }else if(errors.version === null){
        //     document.getElementById('version-drop').classList.remove('is-invalid');
        //     document.getElementById('version-drop-warning').classList.remove('is-invalid');
        //     console.log('errors.version')
        // }
        return ( 
            <React.Fragment>
                <section className={`parent register-wrapper`}>
                    <div className={`login`}>   
                        <div className={`left-part`}>
                            <div className={`logo`}>
                                <img src="../img/logo.svg" alt="alt"/>                                
                            </div>
                            <form onSubmit={this.handleSubmit} className="login-form">
                                <span className="title-form text-change">Register Form</span>
                                <div className="register-input">
                                    {this.renderInput("firstName", null, 'firstName', firstName, this.handleChange, errors.firstName, false, getLanguage().first_name)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("lastName", null, 'lastName', lastName, this.handleChange, errors.lastName, false, getLanguage().last_name)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("email", null, 'email', email, this.handleChange, errors.email, false, getLanguage().email_placeholder)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("repeatEmail", null, 'repeatEmail', repeatEmail, this.handleChange, errors.repeatEmail, false, getLanguage().e_password)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("password", null, "password", password, this.handleChange, errors.password, false, getLanguage().password_placeholder, false, "Password")}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("confirmPassword", null, "confirmPassword", confirmPassword, this.handleChange, errors.password, false, getLanguage().c_password, false, 'Password')}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("street", null, 'street', street, this.handleChange, errors.street, false, getLanguage().street)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("houseNumber", null, 'houseNumber', houseNumber, this.handleChange, errors.houseNumber, false, getLanguage().home_nr, false, 'number')}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("zipCode", null, 'zipCode', zipCode, this.handleChange, errors.zipCode, false, getLanguage().zip_code)}
                                </div>
                                <div className="register-input">
                                    {this.renderInput("city", null, 'city', city, this.handleChange, errors.city, false, getLanguage().city)}
                                </div>
                                <div className="register-input">
                                    <div id="version-drop" className={`input-field input-control ${inputClasses}`}>
                                        <span className="second-title text-change">{getLanguage().choose_version}</span>
                                        <select name='version' id="search_set" value={version} onChange={this.handleChange} className={`text-change`}>
                                            <option value="" disabled>{getLanguage().choose_version}</option>
                                            <option value="VISIOcoach Hemianopia">VISIOcoach Hemianopia</option>
                                            <option value="VISIOcoach Hemianopia KIDS">VISIOcoach Hemianopia KIDS</option>
                                            <option value="VISIOcoach Retinitis pigmentosa">VISIOcoach Retinitis pigmentosa</option>
                                            <option value="VISIOcoach professional">VISIOcoach professional</option>
                                        </select>
                                        <div className={`warning-circle ${inputWarning}`}>
                                            <img onClick={this.onDropDownClickError.bind(this)} src="../img/warning-sign.svg" alt=""/>
                                        </div>
                                        <div className={`warning-tooltip `} >
                                            <p className={`error-message `}>{errors.version}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="loginButton">
                                    {this.renderSubmitButton(getLanguage().register, false)}
                                </div>
                                <div className="go-to-login"> 
                                    <span className="text-change">{getLanguage().already_account}</span>
                                    <Link className="text-change" to="/login">Login</Link>
                                </div>
                                
                            </form>
                            {/* <div className="go-to-homePage">
                                <Link to="">&#8592; Back to HomePage</Link> 
                            </div> */}
                        </div>
                        <div className={`right-part`}>
                            <RightPart />
                        </div>
                    </div>
                </section>
            </React.Fragment>
         ); 
    }
}
 
export default Register;
import React, { Component } from 'react';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import RenderComponent from '../sections/render-component';
import UpgradeElement from './upgradeElement';
import UpgradeSection from '../sections/upgradesection';

class Upgrade extends Component {
    state = {};
    render() { 
        return ( 
            <RenderComponent>
                <LeftMenuSection>
                    <LeftMenu/>
                </LeftMenuSection>
                <UpgradeSection>
                    <UpgradeElement />
                </UpgradeSection>
            </RenderComponent>
         );
    }
}

export default Upgrade;
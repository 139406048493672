import React, { Component } from 'react';
import RenderComponent from '../sections/render-component';
import ProfileSection from '../sections/profilesection';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import ProfileElement from './profileElement';

class Session extends Component {
    state = {};
    render() { 
        return ( 
        <RenderComponent>
            <LeftMenuSection>
                <LeftMenu/>
            </LeftMenuSection>
            <ProfileSection>
                <ProfileElement />
            </ProfileSection>
        </RenderComponent>
         );
    }
}
 
export default Session;
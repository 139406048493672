import React, { Component } from 'react';
import { getLanguage } from '../../global/language'

class Fail extends Component {
    state = { 
        active: false
     }
    render() { 
        const { active, onFail } = this.props;
        return ( 
            <React.Fragment>
                <div className={`info-parent ${active ? 'active' : ''}`}>
                    <div className="image">
                        <img src="../img/fail.svg" alt=""/>
                    </div>
                    <div className="content">
                        <span>{getLanguage().do_better}</span>
                        <button onClick={onFail}>{getLanguage().got_it}</button>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default Fail;
import React, { Component } from 'react';
import { getLanguage } from '../../global/language'

class Success extends Component {
    state = { 
        active: false
     }
    render() { 
        const { active, onSucces } = this.props;
        return ( 
            <React.Fragment>
                <div className={`info-parent ${active ? 'active' : ''}`}>
                    <div className="image">
                        <img src="../img/done.svg" alt=""/>
                    </div>
                    <div className="content">
                        <span>{getLanguage().finished}</span>
                        <button onClick={onSucces}>{getLanguage().continue}</button>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default Success;
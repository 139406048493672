import React from 'react';
import { withRouter } from 'react-router';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import { SketchPicker } from 'react-color';
import { getLanguage } from '../../global/language'
import sessingSessionService from '../../services/settingSessionService';
import * as toast from '../../all/toast'
import Monkey from '../settings/versionComponents/rp/monkey';

class CustomizedElement extends Form {
    state = {
        data: {
            user_id: '5d3085804ae6142fb2e1146d',
            min_search_symbols: 10,
            max_search_symbols: 10,
            total_symbols: 20,
            number_of_screens: 20,
            symbol_size: 30,
            symbol_set: 'single_digits',
            symbol_primary_color: 'ddd',
            symbol_bg_color: 'f00',
            bg_image: 'ff0',
            use_replacement_symbol: true,
            replacement_string: '$',
            replacement_primary_color: "ffaa33",
            replacement_bg_color: "aa22ff",
            target: 'click'
        },
        firefox: false,
        errors: {},
        color: '',
        displayColorPicker: false,
        SymbolBgColor: false,
        bgImage: false,
        backgroundColor: '',
        symbolBackgroundColor: '',
        symbolColor: '',
    }
    
    schema = {
        min_search_symbols: Joi.number().integer().required(),
        max_search_symbols: Joi.number().integer().required(),
        total_symbols: Joi.number().integer().required(),
        symbol_size: Joi.number().integer().required(),
    }

    //PRIMARY COLOR 
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    
    handleChangeComplete = (color) => {
        const { data } = this.state;
        data.symbol_primary_color = color.hex.split('#')[1];
        const value = color.hex
        this.setState({
            data,
            symbolColor: value
        })
    };
    // SYMBOL BG COLOR
    handleClickSymbolBgColor = () => {
        this.setState({ SymbolBgColor: !this.state.SymbolBgColor })
    };
    
    handleCloseSymbolBgColor = () => {
        this.setState({ SymbolBgColor: false })
    };
    handleChangeSymbolBgColor = (color) => {
        const { data } = this.state;
        data.symbol_bg_color = color.hex.split('#')[1];
        const value = color.hex
        this.setState({
            data,
            symbolBackgroundColor: value
        })
    };
    // BG IMAGE
    handleClickbgImage = () => {
        this.setState({ bgImage: !this.state.bgImage })
    };
    
    handleClosebgImage = () => {
        this.setState({ bgImage: false })
    };
    handleChangebgImage = (color) => {
        const { data } = this.state;
        data.bg_image = color.hex.split('#')[1];
        const value = color.hex
        this.setState({
            data,
            backgroundColor: value
        })
    };

    dropdDownChange = (e) => {
        const {data} = this.state;
        data.symbol_set = e.target.value;
        this.setState({
            data
        });
    }
    onFirefox = () => {
        // Firefox 1.0+
        const {data} = this.state;
        var isFirefox = typeof InstallTrigger !== 'undefined';
        
        if(!!isFirefox)
        {
            document.documentElement.mozRequestFullScreen();
        }

        // Safari 30+ && Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        if(!!isChrome || navigator.userAgent.indexOf("Safari") !== -1)
        {
            document.documentElement.webkitRequestFullscreen();
        }
        this.props.history.push({
            pathname: '/session1',
            state: {
                version:'customized',
                user_id: data.user_id,
                min_search_symbols: parseInt(data.min_search_symbols),
                max_search_symbols: parseInt(data.max_search_symbols),
                total_symbols: parseInt(data.total_symbols),
                num_different_search_symbols:1,
                number_of_screens: parseInt(data.number_of_screens),
                symbol_size: parseInt(data.symbol_size),
                symbol_set: data.symbol_set,
                symbol_primary_color: data.symbol_primary_color,
                symbol_bg_color: data.symbol_bg_color,
                bg_image: data.bg_image,
                use_replacement_symbol: data.use_replacement_symbol,
                replacement_string: data.replacement_string,
                replacement_primary_color: data.replacement_primary_color,
                replacement_bg_color: data.replacement_bg_color,
                target: 'click'
            }
        })  
        this.setState({
            firefox: !this.state.firefox
        })
        const { professionalVersion } = this.props;
        sessingSessionService.changeSettings( data, professionalVersion ).then(({ data: response }) => {   
        }).catch(err => {
            toast.error(`${getLanguage().error_}`)
        })
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.settings.number_of_screens)
        {
            this.setState({
                data: {
                    min_search_symbols: nextProps.settings.min_search_symbols,
                    max_search_symbols: nextProps.settings.max_search_symbols,
                    total_symbols: nextProps.settings.total_symbols,
                    number_of_screens: nextProps.settings.number_of_screens,
                    symbol_size: nextProps.settings.symbol_size,
                    symbol_set: nextProps.settings.symbol_set,
                    symbol_primary_color: nextProps.settings.symbol_primary_color,
                    symbol_bg_color: nextProps.settings.symbol_bg_color,
                    bg_image: nextProps.settings.bg_image,
                    use_replacement_symbol: nextProps.settings.use_replacement_symbol,
                    replacement_string: nextProps.settings.replacement_string,
                    replacement_primary_color: nextProps.settings.replacement_primary_color,
                    replacement_bg_color: nextProps.settings.replacement_bg_color,
                    target: 'click'
                },
            })
        }
    }

    render() { 
        const { data, errors, backgroundColor, symbolBackgroundColor, symbolColor } = this.state;
        const { min_search_symbols, max_search_symbols, total_symbols, symbol_size, symbol_set, symbol_primary_color, symbol_bg_color, bg_image} = data;
        const pStyle = {
            fontSize: symbol_size + 'px',
            color: `#${symbol_primary_color}`
        }
        const shapeSquareStyle = {
            fontSize: symbol_size + 'px',
            backgroundColor: `#${symbol_primary_color}`,
            width: symbol_size + 'px',
            height: symbol_size + 'px',
            border: '1px solid' + `#${symbol_primary_color}`,
        }
        const shapeCircleStyle = {
            fontSize: symbol_size + 'px',
            backgroundColor: `#${symbol_primary_color}`,
            width: symbol_size + 'px',
            height: symbol_size + 'px',
            border: '1px solid' + `#${symbol_primary_color}`,
            borderRadius: '50%',
        }
        return ( 
            <React.Fragment>
                <div className="settings">
                    <form onSubmit={this.handleSubmit} >
                        <div className="settings-session">
                            <span className="first-title">{getLanguage().game_costumize}</span>
                                <div className="checkbox customized">
                                    {this.renderInput("min_search_symbols", null, 'min_search_symbols', min_search_symbols, this.handleChange, errors.min_search_symbols, false, `${getLanguage().min_search}`, false, 'number')}
                                    {this.renderInput("max_search_symbols", null, 'max_search_symbols', max_search_symbols, this.handleChange, errors.max_search_symbols, false, `${getLanguage().max_search}`, false, 'number')}
                                    {this.renderInput("total_symbols", null, 'total_symbols', total_symbols, this.handleChange, errors.total_symbols, false, `${getLanguage().all_symbol}`, false, 'number')}
                                    {this.renderInput("symbol_size", null, 'symbol_size', symbol_size, this.handleChange, errors.symbol_size, false, `${getLanguage().symbol_size}`, false, 'number')}
                                    <div className="input-field">
                                        <span className="second-title text-change">{getLanguage().set_symbol}</span>
                                        <select name="symbol-set" className="text-change" id="symbol-set" value={this.state.symbol_set} onChange={this.dropdDownChange}>
                                            <option value="single_digits">{getLanguage().single_digits}</option>
                                            <option value="double_digits">{getLanguage().double_digits}</option>
                                            <option value="single_letters">{getLanguage().single_letters}</option>
                                            <option value="double_letters">{getLanguage().double_letters}</option>
                                            <option value="shapes_1">{getLanguage().predefined_shapes_1}</option>
                                            <option value="shapes_2">{getLanguage().predefined_shapes_2}</option>
                                            <option value="animals">{getLanguage().animal_sil}</option>
                                        </select>
                                    </div>
                                    <div className="input-field">
                                        <span className="second-title text-change">{getLanguage().symbol_primary_color}</span>
                                        <div className="swatch" onClick={ this.handleClick }>
                                            <div className="color" style={{ background: `#${this.state.data.symbol_primary_color}`}} />
                                        </div>
                                        { this.state.displayColorPicker ? <div className="popover">
                                        <div className="cover" onClick={ this.handleClose }/>
                                        <SketchPicker color={ this.state.data.symbol_primary_color } onChange={ this.handleChangeComplete } />
                                        </div> : null }
                                    </div>
                                    <div className="input-field">
                                        <span className="second-title text-change">{getLanguage().symbol_background}</span>
                                        <div className="swatch" onClick={ this.handleClickSymbolBgColor }>
                                            <div className="color" style={{ background: `#${this.state.data.symbol_bg_color}`}} />
                                        </div>
                                        { this.state.SymbolBgColor ? <div className="popover">
                                        <div className="cover" onClick={ this.handleCloseSymbolBgColor }/>
                                        <SketchPicker color={ this.state.data.symbol_bg_color } onChange={ this.handleChangeSymbolBgColor } />
                                        </div> : null }
                                    </div>
                                    <div className="input-field">
                                        <span className="second-title text-change">{getLanguage().background_color}</span>
                                        <div className="swatch" onClick={ this.handleClickbgImage }>
                                            <div className="color" style={{ background: `#${this.state.data.bg_image}`}} />
                                        </div>
                                        { this.state.bgImage ? <div className="popover">
                                        <div className="cover" onClick={ this.handleClosebgImage }/>
                                        <SketchPicker color={ this.state.data.bg_image } onChange={ this.handleChangebgImage } />
                                        </div> : null }
                                    </div>
                                </div>
                            <button className="text-change" type="button" onClick={this.onFirefox} >{getLanguage().start}</button>
                        </div>
                        <div className={`target-preview`} style={{backgroundColor: `#${bg_image}`}}>
                            {/* <span>Targer Preview</span> */}
                            <div className={`target-box`} style={{backgroundColor: `#${symbol_bg_color}`}}>
                                {symbol_set === 'single_digits' &&
                                    <p style={pStyle} >0</p>
                                }
                                {symbol_set === 'double_digits' &&
                                    <p style={pStyle} >00</p>
                                }
                                {symbol_set === 'single_letters' &&
                                    <p style={pStyle} >A</p>
                                }
                                {symbol_set === 'double_letters' &&
                                    <p style={pStyle} >AA</p>
                                }
                                {symbol_set === 'shapes_1' &&
                                    <p style={shapeSquareStyle} ></p>
                                }
                                {symbol_set === 'shapes_2' &&
                                    <p style={shapeCircleStyle} ></p>
                                }
                                {symbol_set === 'animals' &&
                                    <Monkey 
                                        font_size={symbol_size}
                                        font_color_target={symbol_primary_color}
                                    />
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(CustomizedElement);
import React, { Component } from 'react';
import RenderComponent from '../sections/render-component';
import LeftMenuSection from '../sections/leftmenusection';
import LeftMenu from '../left-menu';
import SettingsSection from '../sections/settingssection';
import SettingsElement from './settingsElement';

class Settings extends Component {
    state = {};
    render() { 
        return ( 
        <RenderComponent>
            <LeftMenuSection>
                <LeftMenu/>
            </LeftMenuSection>
            <SettingsSection>
                <SettingsElement />
            </SettingsSection>
        </RenderComponent>
         );
    }
}
 
export default Settings;
import React, { Component } from 'react';
import RenderComponent from '../../../sections/render-component';
import ProfessionalSection from '../../../sections/professionalsection';
import LeftMenuSection from '../../../sections/leftmenusection';
import LeftMenu from '../../../left-menu';
import ProfessionalElement from './professionalElement';

class Professional extends Component {
    state = {};
    render() { 
        return ( 
            <RenderComponent>
                <LeftMenuSection>
                    <LeftMenu/>
                </LeftMenuSection>
                <ProfessionalSection>
                    <ProfessionalElement />
                </ProfessionalSection>
            </RenderComponent>
        );
    }
}
 
export default Professional;
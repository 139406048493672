import React, {Component} from 'react';
import { withRouter } from 'react-router';
import auth from '../../services/authService';
import { getLanguage } from '../../global/language';

class SessionElement extends Component {
    onClickHermianopia = () => {
        this.props.history.push({
            pathname: '/predefined',
            state: {
                currentTab: 'hermianopia'
            }
        })  
    }

    onClickHermianopiaKids = () => {
        this.props.history.push({
            pathname: '/predefined',
            state: {
                currentTab: 'hermianopia-kids'
            }
        })  
    }
    
    onClickRpVersion = () => {
        this.props.history.push({
            pathname: '/predefined',
            state: {
                currentTab: 'rp-version'
            }
        })  
    }
    
    onClickProfessional = () => {
        this.props.history.push({
            pathname: '/professional',
            state: {
                currentTab: 'professional'
            }
        })  
    }
    
    render() {
        const user = auth.getCurrentUser();
        let subscription = user.subscription;
        return(
            <React.Fragment>
                <div className="session">
                    <span>{getLanguage().choose_session}</span>
                    <div className="session-boxes">
                        <div className={`tooltip-session`}>
                            <div onClick={this.onClickHermianopia} className="session-box">
                                <div className="img"></div>
                                <p>Hermianopia</p>
                            </div>
                        </div>
                        <div className={`tooltip-session`}>
                            <div onClick={this.onClickHermianopiaKids} className="session-box">
                                <div className="img"></div>
                                <p>Hermianopia Kids</p>
                            </div>
                        </div>
                        <div className={`tooltip-session `}>
                            <div onClick={this.onClickRpVersion} className="session-box">
                                <div className="img"></div>
                                <p>RP Version</p>
                            </div>
                        </div>
                        <div className={`tooltip-session`}>
                            <div onClick={this.onClickProfessional} className={`session-box ${subscription === 'Inactive' || subscription === 'Trial' ? 'disable' : ''}`}>
                                <div className="img"></div>
                                <p>Professional</p>
                                <span className="tooltip-span">Not avaialble for the unpaid service</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default withRouter(SessionElement);
import React, { Component } from 'react';


class Monkey extends Component {
    constructor(props)
    {
        super(props);
        this.state = {

        }
    }

    render() {
        const { font_size, font_color_target } = this.props;
        return (
            <React.Fragment>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" fill={`${font_color_target}`} x="0px" y="0px"
                    width={`${font_size}`} height={`${font_size}`} viewBox="0 0 580.711 580.711"
                    xml="preserve">
                <g>
	<g>
		<path d="M573.211,113.437c-1.762-0.906-3.65-1.567-5.434-2.44c-11.85-5.818-23.682-11.665-35.518-17.507
			c-0.094-0.049-0.125-0.22-0.273-0.494c1.42-1.106,2.861-2.244,4.318-3.358c1.211-0.93,1.762-2.158,1.207-3.582
			c-0.734-1.881-1.975-3.603-2.453-5.528c-1.047-4.207-4.736-5.704-7.734-7.724c-3.027-2.036-6.582-3.28-9.842-4.986
			c-2.012-1.053-3.979-2.219-5.842-3.513c-4.207-2.921-8.234-6.12-12.525-8.907c-6.193-4.023-13.191-5.443-20.49-5.732
			c-2.26-0.09-4.504-0.542-6.756-0.828h-6.023c-0.33,0.122-0.652,0.29-0.99,0.363c-5.871,1.322-11.828,2.342-17.598,4.011
			c-8.748,2.53-16.447,6.908-21.689,14.676c-1.076,1.6-1.645,3.562-2.33,5.402c-0.543,1.453-0.627,3.142-1.398,4.435
			c-1.783,2.991-3.754,5.892-5.844,8.678c-4.43,5.908-11.455,9.323-15.34,15.786c-6.582,4.659-13.203,9.351-20.885,11.954
			c-7.631,2.583-15.6,3.709-23.678,4.092c-2.023,0.098-4.43,0.604-5.879,1.84c-1.277,1.089-2.383,2.036-4.002,2.346
			c-4.459,0.857-8.955,1.563-13.371,2.611c-2.305,0.547-4.41,1.922-6.711,2.505c-6.174,1.562-12.387,2.978-18.613,4.3
			c-5.344,1.135-10.738,2.02-16.095,3.101c-4.3,0.869-8.581,1.865-12.864,2.815c-2.897,0.641-5.859,1.082-8.666,1.991
			c-9.086,2.942-18.197,5.602-27.891,5.483c-2.346-0.028-4.732,0.2-7.034,0.645c-4.435,0.856-8.801,1.873-13.146,3.305
			c-4.916,1.62-10.31,2.056-15.537,2.411c-8.348,0.563-16.728,0.661-25.096,0.877c-1.024,0.024-2.077-0.396-3.089-0.702
			c-4.337-1.305-7.503-4.198-10.367-7.568c-2.073-2.439-4.113-4.994-6.589-6.968c-2.125-1.697-4.798-2.762-7.315-3.909
			c-9.804-4.459-19.592-8.911-30.065-11.718c-6.667-1.787-13.313-3.052-20.229-2.677c-0.42,0.024-0.849-0.163-1.457-0.286
			c0.494-0.392,0.743-0.591,1.159-0.922c-4.676,0-9.151-0.485-13.484,0.102c-8.548,1.155-16.577,4.015-23.211,9.804
			c-3.146,2.746-6.76,5.374-8.833,8.85c-4.766,7.984-9.225,16.238-12.873,24.782c-4.28,10.024-7.805,20.4-11.179,30.775
			c-1.501,4.615-1.787,9.617-2.705,14.423c-0.64,3.342-1.464,6.65-2.125,9.988c-1.869,9.466-3.721,18.919-7.373,27.936
			c-1.13,2.787-1.734,5.883-2.032,8.89c-0.649,6.524-1.379,13.02-3.484,19.258c-2.868,8.499-5.863,16.96-15.059,20.93
			C0.563,293.589,0.29,293.83,0,294.025v2.15c0.286,0.592,0.881,1.229,0.804,1.762c-0.29,1.926,0.714,2.624,2.313,3.199
			c4.406,1.583,8.172,0.347,11.457-2.582c9.119-8.124,16.137-17.749,19.355-29.646c4.517-16.695,9.184-33.382,12.787-50.286
			c2.832-13.281,6.769-26.198,10.506-39.197c0.686-2.383,1.518-4.725,2.162-7.12c2.628-9.788,6.34-18.858,14.705-25.41
			c3.366-2.636,6.891-4.015,11.089-4.325c6.634-0.494,12.615,1.301,18.466,4.271c6.809,3.456,11.803,8.866,16.777,14.398
			c2.742,3.048,5.577,6.132,9.967,6.132c-0.408,0.869-0.897,1.852-1.338,2.856c-0.444,1.016-0.636,2.211-1.297,3.043
			c-1.514,1.897-3.211,3.656-4.9,5.41c-4.239,4.406-7.916,9.245-10.535,14.766c-3.46,7.299-4.953,15.125-5.173,23.15
			c-0.18,6.5-0.192,12.995-1.783,19.355c-0.836,3.346-2.032,6.646-2.477,10.041c-0.608,4.659-0.514,9.404-0.979,14.084
			c-0.526,5.312-1.322,10.6-2.036,15.892c-0.42,3.097-1.228,6.173-1.306,9.274c-0.191,7.662-0.188,15.333-0.032,22.995
			c0.236,11.701,1.236,23.297,4.21,34.717c2.411,9.249,5.063,18.217,12.256,25.084c0.983,0.938,1.412,2.166-0.677,2.578
			c-0.494,0.098-0.93,0.551-1.371,0.869c-2.905,2.105-5.561,4.794-8.784,6.132c-2.342,0.972-3.031,2.53-3.904,4.305
			c-1.881,3.823-3.501,7.785-5.574,11.497c-3.015,5.41-6.417,10.601-9.498,15.974c-4.451,7.76-9.453,15.1-17.099,20.004
			c-5.431,3.484-10.474,7.364-15.455,11.457c-8.164,6.707-8.613,15.602-3.146,23.737c1.567,2.334,4.088,4.093,4.378,7.214
			c0.017,0.163,0.273,0.306,0.417,0.457c1.142,1.195,2.317,2.357,3.415,3.586c3.317,3.717,6.981,7.205,9.809,11.269
			c3.142,4.513,5.691,9.47,8.164,14.398c2.321,4.627,3.811,9.576,10.351,10.163c5.239,0.474,8.413-0.746,10.469-5.435
			c0.11-0.248,0.216-0.497,0.241-0.559c-0.853-1.452-3.529-0.841-3.292-3.599c3.603,0.266,7.058,0.298,10.437,0.828
			c3.688,0.58,7.462,1.22,10.906,2.579c3.199,1.261,6.353,1.673,9.617,1.383c1.921-0.171,4.488,0.567,5.304-2.432
			c0.078-0.286,1.404-0.408,2.093-0.294c0.579,0.098,1.126,0.604,1.611,1.024c3.027,2.627,6.365,2.048,9.658,0.8
			c0.779-0.298,1.628-1.314,1.812-2.143c0.979-4.386-1.963-11.081-5.753-13.354c-1.995-1.191-4.031-2.321-6.108-3.366
			c-2.742-1.375-5.541-2.632-8.319-3.941c-0.898-0.424-1.816-0.808-2.697-1.265c-2.138-1.109-4.263-2.248-6.389-3.387
			c-4.251-2.284-9.188-2.28-13.607-4.451c-4.37-2.146-7.829-5.071-11.228-8.303c-1.514-1.44-1.465-2.876-0.224-4.504
			c1.297-1.697,2.228-3.726,3.705-5.227c2.427-2.465,5.08-4.745,7.821-6.862c1.652-1.277,3.778-1.926,5.479-3.154
			c4.973-3.599,9.947-7.213,14.725-11.061c6.842-5.508,14.305-10.277,20.196-16.933c3.656-4.129,7.303-8.282,11.196-12.179
			c7.748-7.752,15.651-15.349,23.529-22.97c1.889-1.828,2.852-1.767,4.562,0.253c0.955,1.13,1.546,2.566,2.48,3.721
			c3.607,4.447,8.197,7.364,13.786,8.702c0.657,0.159,1.677,0.511,1.792,0.959c0.938,3.688,1.705,7.422,2.501,11.146
			c0.037,0.176-0.114,0.408-0.212,0.596c-2.044,3.938-2.489,8.168-2.105,12.489c0.392,4.39,0.996,8.756,1.53,13.134
			c0.225,1.832,0.481,3.663,0.763,5.487c0.804,5.259,1.693,10.506,2.423,15.777c0.4,2.896,0.363,5.858,0.853,8.735
			c0.845,4.97,1.191,9.869,0.143,14.871c-1.15,5.5-1.036,11.212-3.9,16.406c-1.534,2.778-2.028,6.152-2.803,9.307
			c-0.196,0.8,0.029,2.134,0.583,2.603c1.718,1.448,3.599,2.767,5.573,3.84c6.173,3.354,12.521,6.393,18.605,9.894
			c5.483,3.154,11.31,4.937,17.532,5.459c2.974,0.249,5.994-0.11,8.988-0.028c13.178,0.363,26.349,0.865,39.531,1.13
			c2.884,0.058,5.825-0.444,8.657-1.068c1.783-0.393,4.195-0.278,4.684-2.987c0.041-0.22,0.857-0.437,1.303-0.416
			c1.645,0.077,3.32,0.551,4.92,0.347c5.785-0.734,11.547-1.648,17.299-2.611c0.385-0.064,0.926-1.134,0.85-1.652
			c-0.408-2.786-2.168-4.553-4.717-5.577c-3.229-1.297-6.451-2.595-9.695-3.848c-5.959-2.305-11.99-4.341-18.367-5.246
			c-4.843-0.686-9.727-1.343-14.439-2.6c-7.826-2.085-15.777-2.313-23.771-2.501c-3.913-0.09-7.715-0.734-10.93-3.101
			c-0.996-0.734-1.881-1.999-2.215-3.187c-1.041-3.688,0.339-7.189,1.51-10.592c1.342-3.884,3.39-7.544,4.496-11.485
			c2.529-9.009,4.533-18.16,7.013-27.181c1.734-6.312,2.852-12.648,2.505-19.196c-0.229-4.288-0.498-8.576-0.857-12.856
			c-0.608-7.233,2.481-13.79,3.855-20.641c0.824-4.108,3.044-7.919,4.476-11.922c0.861-2.411,1.571-4.978,0.547-7.507
			c-1.856-4.578-2.249-9.348-2.46-14.215c-0.2-4.554-0.469-9.151-1.338-13.611c-0.955-4.896-2.868-9.547-6.495-13.219
			c-2.326-2.358-3.938-5.026-4.194-8.396c-0.029-0.347-0.045-0.951-0.192-0.991c-2.028-0.571-1.856-2.448-2.358-3.896
			c-2.028-5.88-4.223-11.714-5.969-17.679c-1.469-5.019-1.767-10.458-3.725-15.243c-0.053-0.13-0.102-0.261-0.155-0.388
			c3.664,0.29,7.323,0.261,10.991,0.583c3.337,0.6,6.589,1.498,9.764,2.693c3.366,1.591,6.544,3.505,9.535,5.745
			c-0.175-0.135-0.351-0.273-0.53-0.408c0.873,0.637,1.75,1.203,2.652,1.685c4.823,3.636,9.764,7.214,15.084,9.996
			c4.056,2.122,8.828,3.239,13.41,3.884c4.357,0.616,8.111,1.698,11.254,4.913c0.922,0.942,2.562,1.424,3.945,1.624
			c2.32,0.334,4.725,0.102,7.055,0.416c3.643,0.493,7.254,1.771,10.873,1.742c3.426-0.024,6.52,0.677,9.686,1.648
			c0.857,0.261,1.852,0.139,2.773,0.085c4.271-0.253,8.541-0.697,12.812-0.767c2.934-0.049,5.699,0.877,7.654,2.383
			c-0.348,2.587-0.73,4.736-0.914,6.903c-0.363,4.264-0.381,8.572-0.988,12.795c-0.473,3.276-1.721,6.43-2.469,9.674
			c-0.926,4.006-1.738,8.041-2.521,12.076c-1.068,5.5-2.215,10.992-3.023,16.532c-0.297,2.04-0.113,4.056-1.477,5.859
			c-0.547,0.722-0.604,1.86-0.738,2.831c-0.445,3.248-0.795,6.508-1.232,9.76c-0.574,4.304-1.615,8.592-1.709,12.9
			c-0.236,10.755-3.162,20.906-6.486,30.955c-1.805,5.447-4.787,10.519-6.387,16.006c-2.436,8.36-3.883,17.006-6.242,25.395
			c-1.162,4.141,0.115,7.972,0.15,11.941c0.006,0.633,0.502,1.314,0.898,1.881c2.35,3.358,5.623,4.807,9.689,4.88
			c6.859,0.127,13.693,0.6,20.531,1.224c4.688,0.429,9.424,0.372,14.141,0.421c1.188,0.012,2.486-0.163,3.547-0.653
			c3.275-1.514,6.314-1.053,9.379,0.56c2.592,1.362,6.076-0.11,6.23-2.113c-1.176-0.865-2.174-1.604-3.354-2.477
			c1.645-0.494,2.721-0.816,3.799-1.143c-0.604-1.322-1.164-2.669-1.836-3.954c-0.254-0.485-0.797-0.815-1.168-1.252
			c-3.324-3.905-7.988-5.578-12.537-7.345c-2.256-0.877-4.578-1.37-7.213-1.117c-2.723,0.261-5.582-0.62-8.336-1.2
			c-1.648-0.347-3.316-0.885-4.803-1.668c-2.811-1.478-3.496-3.452-2.342-6.431c1.48-3.811,2.908-7.662,4.676-11.342
			c4.02-8.389,8.404-16.606,12.322-25.043c4.729-10.185,9.195-20.49,13.615-30.812c1.186-2.778,1.867-5.79,2.619-8.735
			c0.75-2.945,1.008-6.038,1.979-8.898c3.635-10.678,7.584-21.245,11.191-31.93c1.578-4.685,2.619-9.548,3.904-14.329
			c0.217-0.033,0.428-0.069,0.645-0.103c0.645,1.139,1.584,2.203,1.873,3.428c0.518,2.183,0.73,4.451,0.922,6.695
			c0.352,4.129,0.486,8.278,0.926,12.398c0.191,1.779,0.576,3.701,1.465,5.211c2.023,3.431,4.125,6.769,4.826,10.783
			c0.314,1.815,0.824,3.594,1.209,5.397c0.93,4.357,2.256,8.687,2.623,13.089c0.293,3.505,1.357,6.475,3.092,9.384
			c0.461,0.775,0.787,1.705,0.906,2.599c0.906,6.83,1.734,13.673,2.623,20.507c0.098,0.755,0.42,1.477,0.621,2.219
			c1.51,5.521,3.336,10.979,4.426,16.577c0.936,4.794,1.016,9.756,2.934,14.357c1.188,2.853,1.188,5.77,0.771,8.813
			c-0.408,2.966-1.041,5.973,1.477,8.687c1.359,1.464,2.24,3.61,2.717,5.602c1.842,7.649,3.387,15.365,5.129,23.04
			c0.797,3.517,1.711,7.009,2.713,10.473c0.418,1.437,1.434,2.188,3.127,2.416c7.396,0.991,14.793,2.044,22.133,3.37
			c3.375,0.607,6.846,1.48,8.996,4.663h2.15c2.355-0.429,4.701-0.886,7.059-1.285c2.795-0.47,5.762-0.474,8.34-1.478
			c1.779-0.693,3.23-1.023,5.01-0.53c2.037,0.563,2.844-0.073,2.307-2.06c-0.318-1.188-1.348-2.188-2.094-3.248
			c-0.191-0.273-0.719-0.44-0.734-0.682c-0.15-2.424-2.273-3.125-3.754-4.357c-1.529-1.277-3.33-2.272-4.709-3.684
			c-5.609-5.745-12.717-8.74-20.139-11.086c-3.59-1.134-7.422-5.12-7.609-8.902c-0.547-10.938-1.473-21.89-1.398-32.828
			c0.123-17.833,0.906-35.663,1.443-53.492c0.104-3.362,0.322-6.72,0.555-10.078c0.486-6.984,1.254-13.958,1.449-20.951
			c0.135-4.843-0.535-9.706-0.768-14.565c-0.277-5.793-0.525-11.587-0.701-17.385c-0.24-8.005-0.857-16.075,1.082-23.933
			c2.287-9.282,4.977-18.462,7.543-27.675c1.387-4.974,3.207-9.788,3.766-15.027c0.727-6.846,2.012-13.635,3.184-20.429
			c0.963-5.589,2.283-11.118,3.137-16.72c0.877-5.769,1.469-11.583,2.016-17.393c0.363-3.872,0.51-7.772,0.6-11.665
			c0.102-4.549-0.387-9.152,0.15-13.644c0.551-4.623,5.639-8.87,9.539-8.87c3.492,0,4.027-0.551,4.129-4.186
			c-0.656-0.249-1.301-0.494-2.223-0.845c2.555-0.792,4.643-1.436,6.736-2.085c-0.053-0.146-0.104-0.298-0.156-0.444
			c-1.236-0.192-2.473-0.384-3.709-0.575c0.018-0.204,0.037-0.408,0.055-0.612h5.283c0-1.236-0.09-2.383,0.041-3.505
			c0.061-0.522,0.453-1.326,0.848-1.432c2.053-0.559,4.158-0.914,6.234-1.387c0.514-0.119,1.289-0.298,1.432-0.649
			c0.67-1.706,2.045-1.538,3.404-1.481c2.1,0.09,4.039-0.277,6.217-2.248c-1.945-0.18-3.312-0.302-4.977-0.453
			c1.305-0.836,2.387-1.481,3.414-2.199c8.119-5.671,17.021-8.181,26.684-5.357c5.564,1.628,10.078,0.167,14.574-2.668
			c0.721-0.457,1.549-0.816,2.133-1.412c3.326-3.382,5.965-7.609,3.619-12.378c-0.633-1.29-0.412-2.232,0.191-3.362
			c2.004-3.737,2.633-7.593,1.064-11.706c-0.338-0.885-0.26-2.118,0.078-3.023c0.506-1.346,1.412-2.542,2.146-3.798v-2.15
			C579.572,117.904,576.803,115.28,573.211,113.437z M224.413,247.215c-0.021-0.114-0.045-0.232-0.07-0.347
			c0.171-0.021,0.335-0.028,0.506-0.049C224.702,246.95,224.555,247.081,224.413,247.215z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
            </React.Fragment>
        );
    }
}

export default Monkey
import React, {Component} from 'react';
import { withRouter } from 'react-router';
import Hermianopia from './versionComponents/hemianopia/hermianopia';
import HermianopiaKids from './versionComponents/kids/hermianopia-kids';
import RpVersion from './versionComponents/rp/rp';
import auth from '../../services/authService'
import settingSessionService from '../../services/settingSessionService';

const user = auth.getCurrentUser();

class SettingsElement extends Component { 
    constructor(props){
        super(props);
        let path = this.props.match.path.split('/')[1]
        if(!path)
        {
            path = this.getPath();
        }
        console.log(path)
        this.state={
            currentTab: path,
            settings: {}
        }
        // if(this.props.location.state){
        //     this.state = this.props.location.state;
        // }else{
        //     props.history.push('session')
        // }
    }

    getPath = () => {
        let version = user.version;
        let path = '';
        if(version === 'VISIOcoach Hemianopia KIDS')
        {
            path = 'hermianopia-kids'
        }
        else if(version === 'VISIOcoach Hemianopia')
        {
            path = 'hermianopia'
        }
        else if(version === 'VISIOcoach Retinitis pigmentosa')
        {
            path = 'rp-version'
        }
        else if(version === 'VISIOcoach professional')
        {
            path = 'professional'
        }
        return path;
    }
    
    componentDidMount() {
        settingSessionService.changeSettingsGet().then(({ data: response }) => {
            console.log(response)
            this.setState({
                settings: response
            })
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }

    render() { 
        const { currentTab, settings } = this.state;
        return ( 
            <React.Fragment>
                <div className="settings">
                    <div className={`hermianopia ${currentTab === "hermianopia" ? 'active' : 'disable'}`}>
                        {currentTab === "hermianopia" &&
                            <Hermianopia 
                                settings={settings}
                                currentTab={currentTab}
                            />
                        }
                    </div>
                    <div className={`hermianopia-kids ${currentTab === "hermianopia-kids" ? 'active' : 'disable'}`}>
                        {currentTab === "hermianopia-kids" &&
                            <HermianopiaKids 
                                settings={settings}
                                currentTab={currentTab}
                            />
                        }
                    </div>
                    <div className={`rp-version ${currentTab === "rp-version" ? 'active' : 'disable'}`}>
                        {currentTab === "rp-version" &&
                            <RpVersion 
                                settings={settings}
                                currentTab={currentTab}
                            />
                        }
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(SettingsElement);